<template>
  <div :class="[notFound ? $style.container_notFound : $style.container]">
<!--
    <div v-if="article && article.categories.length">
    <SideListTitle>似ている記事</SideListTitle>
    <Spacer :y="2.5" />
    <ul>
      <SideListItem v-for="item in articles.related" :item="item" :key="item.title" />
    </ul>
    <Spacer :y="4" />
    <router-link
    :class="$style.more"
    :to="`/category/${article.categories[0].name}/`">もっと見る</router-link>
    <Spacer :y="4" />
    <Spacer :y="4" />
    </div>

    <SideListTitle>おすすめ記事</SideListTitle>
    <Spacer :y="2.5" />
    <ul v-if="articles.recommend.length">
      <SideListItem
        v-for="item in articles.recommend"
        :item="item"
        :key="item.id"
      />
    </ul>
    <Spacer :y="4" />
    <router-link
      to="/category/recommend"
      :class="$style.more">もっと見る</router-link>

      <Spacer :y="4"/>
      <SideListTitle>タグで探す</SideListTitle>
      <Spacer :y="2.5" />

      <TagList v-if="tags.length">
        <TagListItem
          v-for="item in tags"
          :key="item.id"
          :tag="item"
        />
      </TagList>
 -->
    <Spacer :y="2.5" />
    <Loading
      class="loader-simple"
      v-if="flag.loader"></Loading>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer';
// import {
//   SideListItem,
//   SideListTitle,
// } from '@/components/SideList';
// import {
//   TagList,
//   TagListItem,
// } from '@/components/TagList';
import Loading from '@/components/Loading.vue';

export default {
  name: 'SideColumn',
  components: {
    Spacer,
    // SideListItem,
    // SideListTitle,
    // TagListItem,
    // TagList,
    Loading,
  },
  data() {
    return {
      articles: {
        recommend: [],
        related: [],
      },
      tags: [],
      flag: {
        loader: true,
      },
      taxonomy: {},
      slug: '',
    };
  },
  props: {
    article: {
      type: Object,
    },
    notFound: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('category', ['categories']),
  },
  created() {
    this.getArticles();
    this.getTags();
    this.getRelatedArticles();
  },
  methods: {
    getArticles() {
      const params = {
        flag: [1],
        limit: 6,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/recommends',
        params,
      })
        .then((response) => {
          this.articles.recommend = response.data.recommends.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    getTags() {
      this.flag.loader = true;
      const params = {
        limit: 10,
      };
      this.axios({
        method: 'GET',
        url: '/v1/tag/get/ranking',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.tags = res.tags.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },
    getRelatedArticles() {
      // 記事情報がない時は返却
      if (!this.article || !this.article.categories.length) {
        return;
      }

      this.slug = this.article.categories[0].name;
      this.taxonomy = this.categories.filter((category) => (
        // 親カテゴリに該当するものがないかチェック
        category.name === this.slug || this.slug === 'recommend'))?.[0]
        // 子カテゴリーにslugに該当するものがあるかチェック
        || this.categories.flatMap((category) => category?.children).filter((child) => child?.name === this.slug)?.[0]
        // カテゴリーにslugに該当するものがなければ空Objectを返す
        || {};

      const sortTarget = [];
      if (this.taxonomy) {
        sortTarget.push(this.taxonomy.id);
        if (this.taxonomy.children?.length) {
          sortTarget.push(...this.taxonomy.children.map((category) => category.id));
        }
      }
      const params = {
        flag: [1],
        // 表示する件数は最大6件
        limit: 6,
        sort: {
          categories: sortTarget,
        },
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/list',
        params,
      })
        .then((response) => {
          this.articles.related = response.data.articles.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style module lang="scss">
.container {
  width: 23%;
  padding-left: calc(var(--s) * 2.5);
  border-left: 1px solid var(--border-gray);

  @include md-view {
    width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: calc(var(--s) * 6);
  }
}
.container_notFound {
  width: 100%;
  padding-left: 0;
  border-left: none;
  margin-top: calc(var(--s) * 6);
}

.banner {
  margin: 0 auto;
}

.more {
  display: block;
  margin: auto;
  width: fit-content;
  padding: 12px 38px;
  font-size: 12px;
  line-height: 1;
  color: rgba(26, 34, 61, 0.72);
  text-align: center;
  border: 1px solid var(--border-gray);
  border-radius: 18px;
  cursor: pointer;
}
</style>
