<template>
  <div>
    <IconBox
      :type="type"
      :url="author.authorurls && author.authorurls.length
        ? author.authorurls[0]
        : ''">
      <div :class="[$style.wrap, $style[type]]">
        <div :class="$style.name_wrap">
          <p :class="$style.name">{{ author.name }}</p>
          <p :class="$style.remark"
            v-if="remark">{{ remark }}</p>
        </div>
        <Links
          v-if="showLinks"
          :links="author.links"
          :showSns="view.display_size === 'sm' ? false : true"/>
      </div>
      <Spacer :y="4" :smY="2" v-if="type === 'lg'" />
      <p
        v-if="type === 'lg' && author.description"
        :class="$style.summary">{{ author.description }}</p>
      <Spacer :y="0" :smY="3" v-if="type === 'lg'" />
      <Links
        v-if="view.display_size === 'sm' && type === 'lg'"
        :links="author.links"
        :showWeb="false"/>
    </IconBox>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconBox from '@/components/IconBox.vue';
import Spacer from '@/components/Spacer.vue';
import Links from '@/components/Links.vue';

export default {
  name: 'author',
  props: {
    type: {
      type: String,
      default: 'lg',
      validator: (value) => ['lg', 'sm'].includes(value),
    },
    author: {
      type: Object,
      required: true,
    },
    showLinks: {
      type: Boolean,
      default: true,
    },
    remark: {
      type: String,
      default: '',
    },
  },
  components: {
    IconBox,
    Links,
    Spacer,
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapState(['view']),
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name_wrap {
    flex: 1;
  }
  .name {
    font-weight: 700;
  }
  .remark {
    font-size: 11px;
    color: rgba(#000, .64);
  }

  &.lg {
    // padding-top: 20px;
    .name_wrap {
      padding-right: 1em;
    }
    .name {
      font-size: 28px;
      line-height: 1.5;
    }
  }
  &.sm {
    .name {
      font-size: 14px;
    }
  }
}


.summary {
  color: var(--font-black);
  font-size: 12px;
  line-height: 24px;
}


@include sm-view {
  .wrap {
    &.lg {
      padding-top: 1em;
      .name {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}
</style>
