<template>
  <div
    class="d-flex align-items-center ms-auto"
    v-if="Object.keys(helper.master).length">
    <ul
      :class="[$style.navList, flag.isShow ? $style.isShow: '']">
      <li
        v-for="nav in helper.master.postTypes"
        :key="nav.name">
        <router-link
          :to="`/${nav.name}/`"
          :class="getIsActive(nav.name)">{{ toupperFirstLetter(nav.name) }}</router-link>
      </li>
    </ul>

    <div :class="$style['btn-menu-wrap']">
      <input type="checkbox" id="btn-menu" @change="menuHandler" :checked="flag.isShow">
      <label for="btn-menu" :class="$style['btn-menu']"></label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'GlobalNav',
  mixins: [cf],
  data() {
    return {
      flag: {
        isShow: false,
      },
      tagType: 'router-link',
    };
  },
  watch: {
    $route() {
      // ページ切り替え毎にメニュー非表示
      this.flag.isShow = false;
    },
  },
  computed: {
    ...mapState(['page', 'helper']),
  },
  methods: {
    /** menu show hide */
    menuHandler(e) {
      const tgt = e.currentTarget;
      const checked = tgt.checked;
      this.flag.isShow = checked;
    },

    /** menu active class */
    getIsActive(name) {
      if (!this.page.name) return;
      return this.page.name.toLowerCase().includes(name)
        ? this.$style.isActive
        : '';
    },

    /**
     * postTypeによって遷移先をサイト内なのか外部サイトかを判定
     * サイト内リンクであればfalseを返し、外部サイトならURLを返す
     * pageコンテンツ追加に伴い修正する際に漏れをなくすためcfに記載
     */
    getLink(type) {
      const result = cf.getLink(type);
      return result;
    },

    // タグのタイプを指定
    tagName(type) {
      let result = 'router-link';
      const link = cf.getLink(type);
      if (!link) {
        result = 'a';
      }
      return result;
    },
  },
};
</script>

<style lang="scss" module>
$size: 56px;
.navList {
  position: relative;
  z-index: 0;
  opacity: 0;
  // transform: translate3d(135%, 0, 0);
  // transition: all 1.5s cubic-bezier(0.68, -0.6, 0.32, 1.6); // easeInOutback
  // transition: all 1.5s cubic-bezier(0.87, 0, 0.13, 1); // easeInOutExpo
  transition: all 1s cubic-bezier(0.87, 0, 0.13, 1); // easeInOutExpo
  display: flex;
  margin-right: $size;
  > li {
    &:not(:first-child) {
      margin-left: $size;
    }
    a {
      font-size: 16px;
      color: var(--brown);
      opacity: .7;
      letter-spacing: 0;
      &.isActive {
        display: flex;
        align-items: center;
        font-weight: bold;
        opacity: 1;
        &:before {
          content: "●";
          font-size: $size / 7;
          margin-right: $size / 7;
          transform: scale(.8);
        }
      }
      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }
  &.isShow {
    opacity: 1;
    // transform: translate3d(0, 0, 0);
  }
}

.btn-menu-wrap {
  position: relative;
  z-index: 1;
  input {
    display: none;
    &:checked + .btn-menu {
      &:before {
        transform: rotate(45deg);
        bottom: 0;
      }
      &:after {
        transform: rotate(-45deg);
        top: -2px;
        margin-top: 0;
      }
    }
  }
  .btn-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    &:before,
    &:after {
      content: "";
      display: block;
      width: $size / 2;
      height: 2px;
      background-color: var(--brown);
      position: relative;
      transition: .4s all;
    }
    &:after {
      margin-top: $size / 7;
    }
  }
}

@include sm-view {
  .navList {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    min-width: 200px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-right: 0;
    opacity: 1;
    > li {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 16px;
      }
      a {
        font-size: 20px;
      }
    }
    &.isShow {
      right: 0;
    }
  }
}
</style>
