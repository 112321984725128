<template>
  <div :class="$style.header">
    <div :class="$style.header_left">
      <h1 class="image" :class="$style.logo">
        <router-link to='/' class="image-body">Funkiryu</router-link>
      </h1>
    </div>

    <div :class="$style.header_right">
      <div
        class="btn btn-sm btn-secondary"
        v-if="user.login.isLogin"
        v-on:click="logout">ログアウト</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'admin-global-header',
  computed: {
    ...mapState(['user', 'helper']),
    isAdmin() {
      return Boolean(this.user);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/login/logout', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
$gray-bg: #f6f6f6;
$black: #2d2d2d;

.header {
  position: fixed;
  top: 0;
  padding: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $gray-bg;
  z-index: 5;
  box-shadow: 0 1px 4px 1px rgba($black, 0.2);
  &_left {
    width: 30%;
    max-width: 120px;
  }

  &_right {
    display: flex;
    align-items: center;
    .btn {
      &.square {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

.logo {
  a {
    transition: all .4s;
    padding-top: percentage(194/532);
    background-image: url(/img/logo/logo_text.png);
    &:hover {
      opacity: .6;
    }
  }
}
</style>
