<template>
  <div :class="$style.slider-wrap">
    <carousel
      :items-to-show="3"
      :transition="500"
      :wrapAround="true"
      :autoplay="10000"
      :pauseAutoplayOnHover="true">
      <slide v-for="slide in sliders" :key="slide" >
        <div :class="$style.slide_item_wrapper">
          <div
            class="carousel__item"
            :style="{ 'backgroundImage' : `url(${slide})`}"
            @click="targetLink(slide)">
          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Slider',
  mixins: [cf],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    sliders: {
      type: Object,
    },
  },
  data() {
    return {
      postType: {
        name: null,
      },
      posts: [],
      flag: {
        loading: true,
      },
    };
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'helper/putMaster') this.setInitLoadState();
    });
  },
  computed: {
    ...mapState(['page', 'helper']),
  },
  methods: {
    // 遷移先のリンクの生成
    async targetLink(data) {
      /**
       * 取り急ぎarticleのみをピックアップするが本来はクーポンやイベントも扱いたい
       */
      let targetLink;
      this.postType.name = 'article';
      await this.getPosts();

      this.posts.forEach((elem) => {
        if (elem.urls[0] === data) {
          targetLink = `/${this.postType.name}/${elem.slug}`;
        }
      });
      if (!targetLink) console.log('no match article');
      this.$router.push(targetLink);
    },

    /** postType毎の記事が読み込まれたかフラグの仕込み */
    setInitLoadState() {
      if (!Object.keys(this.helper.master).length) return;
      this.helper.master.postTypes.forEach((postType) => {
        this.postLoaded[postType.name] = false;
      });
    },

    async getPosts() {
      // 取り急ぎダミー
      // 実際にはpostType.nameのデータを取得する
      const posts = cloneDeep(this.helper.master.posts[this.postType.name]);
      if (!posts) {
        this.flag.loading = false;
        return;
      }
      // 画像の向きを取得する
      const inPiecesPosts = [];
      await Promise.all(
        posts.map(async (post, index) => {
          // 暫定処理
          if (!post.urls || !post.urls[0]) {
            post.urls = ['/img/logo/basic.png'];
            post.noimage = true;
          }
          const imageDirection = await cf.getImageDirection(post.urls[0]);
          post.direction = imageDirection;
          post.index = index;
          inPiecesPosts.push(post);
        }),
      );
      // direction取得時に順番狂うためソート
      const sortedPosts = await cf.ObjectValueSort(inPiecesPosts);
      sortedPosts.forEach((p) => { this.posts.push(p); });
    },
  },
};
</script>

<style lang="scss" module>
.slider-wrap {
  width: 100%;
  box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.2);
}
.slide_item_wrapper {
  width: 95%;
  margin: 0 auto;
  &:hover {
    overflow: hidden;
  }
}

</style>

<style lang="scss">
.carousel__item {
  background-size: cover;
  padding-top: 95%;
  width: 100%;
  &:hover {
    opacity: .7;
    transform: scale(1.1);
    transition: opacity .3s;
    transition: transform .3s;
  }
}

.carousel__slide {
}

.carousel__prev {
  top: 48%;
  left: 10px;
  transform: translate(0%, -50%);
}
.carousel__next {
  top: 48%;
  right: 10px;
  transform: translate(0%, -50%);
}
.carousel__prev,
.carousel__next {
  height: 40px;
  width: 30px;
  box-sizing: content-box;
  background-color: rgba(255, 255, 255, 0.5);
  // border-radius: 100%;
  text-align: center;
  transition: 0.5s;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
}
@media screen and (max-width: 576px) {
  .carousel__prev,
  .carousel__next {
    height: 50px;
    width: 50px;
    box-sizing: content-box;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    text-align: center;
    transition: 0.5s;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}


.carousel__icon {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  width: 95%;
  height: 95%;
  transition: 0.2s;
  &:hover {
    width: 100%;
    height: 100%;
    color: rgba(125, 125, 125, 1);
  }
}

.carousel__pagination {
  padding-top: 2%;
  margin: 0;
}


.carousel__pagination-button {
  padding: 5px;
  color: #CCCCCC;
}

.carousel__pagination-button--active {
  color: #333333;
}


</style>
