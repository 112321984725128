import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home/index.vue';


/** @Account */
import Login from '@/views/Account/Login.vue';
import Signup from '@/views/Account/Signup.vue';
import SignupPassword from '@/views/Account/SignupPassword.vue';

/** @Posts */
import Archive from '@/views/Posts/Archive.vue';
import SinglePost from '@/views/Posts/_slug.vue';

/** @Admin */
import AdminIndex from '@/views/Admin/index.vue';
import AdminArticleList from '@/views/Admin/Article/List.vue';
import AdminArticleCreate from '@/views/Admin/Article/Create.vue';
import AdminArticleUpdate from '@/views/Admin/Article/Update.vue';
import AdminEventList from '@/views/Admin/Event/List.vue';
import AdminEventCreate from '@/views/Admin/Event/Create.vue';
import AdminEventUpdate from '@/views/Admin/Event/Update.vue';
import AdminShopList from '@/views/Admin/Shop/List.vue';
import AdminShopCreate from '@/views/Admin/Shop/Create.vue';
import AdminShopUpdate from '@/views/Admin/Shop/Update.vue';
import AdminProfile from '@/views/Admin/Common/Profile.vue';

/** @Error */
import NotFound from '@/views/Error/NotFound.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    children: [
      {
        path: '/signup/password',
        name: 'signup/password',
        component: SignupPassword,
      },
    ],
  },
  /** @Event */
  {
    path: '/event',
    name: 'Archive-event',
    component: Archive,
  },
  {
    path: '/event/:slug',
    name: 'Single-event',
    component: SinglePost,
  },


  /** @Article */
  {
    path: '/article',
    name: 'Archive-articles',
    component: Archive,
  },
  {
    path: '/article/:slug',
    name: 'Single-article',
    component: SinglePost,
  },

  /** @News */
  {
    path: '/news',
    name: 'Archive-news',
    component: Archive,
  },
  {
    path: '/news/:slug',
    name: 'Single-news',
    component: SinglePost,
  },

  /** @Shop */
  {
    path: '/shop',
    name: 'Archive-shop',
    component: Archive,
  },
  {
    path: '/shop/:slug',
    name: 'Single-shop',
    component: SinglePost,
  },

  /** @Coupon */
  {
    path: '/coupon',
    name: 'Archive-coupon',
    component: Archive,
  },
  {
    path: '/coupon/:slug',
    name: 'Single-coupon',
    component: SinglePost,
  },

  /** @News */
  {
    path: '/news',
    name: 'Archive-news',
    component: Archive,
  },

  /** @Educate */
  {
    path: '/educate',
    name: 'Archive-educate',
    component: Archive,
  },

  /** @Work */
  {
    path: '/work',
    name: 'Archive-work',
    component: Archive,
  },

  /** @Admin */
  {
    path: '/admin',
    name: 'Admin-index',
    component: AdminIndex,
  },
  {
    path: '/admin/article/',
    name: 'Admin-article-list',
    component: AdminArticleList,
  },
  {
    path: '/admin/article/create',
    name: 'Admin-article-create',
    component: AdminArticleCreate,
  },
  {
    path: '/admin/article/update/:slug',
    name: 'Admin-article-update',
    component: AdminArticleUpdate,
  },
  {
    path: '/admin/event/',
    name: 'Admin-event-list',
    component: AdminEventList,
  },
  {
    path: '/admin/event/create',
    name: 'Admin-event-create',
    component: AdminEventCreate,
  },
  {
    path: '/admin/event/update/:slug',
    name: 'Admin-event-update',
    component: AdminEventUpdate,
  },
  {
    path: '/admin/shop/',
    name: 'Admin-shop-list',
    component: AdminShopList,
  },
  {
    path: '/admin/shop/create',
    name: 'Admin-shop-create',
    component: AdminShopCreate,
  },
  {
    path: '/admin/shop/update/:slug',
    name: 'Admin-shop-update',
    component: AdminShopUpdate,
  },
  {
    path: '/admin/profile',
    name: 'Admin-profile',
    component: AdminProfile,
  },

  /** @Error */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'お探しのページは見つかりませんでした',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    const hash = decodeURIComponent(to.hash);
    if (!hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: hash };
    }
    return position;
  },
});

export default router;
