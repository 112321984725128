<template>
  <div :class="$style.container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ColumnContainer',
};
</script>

<style module lang="scss">
.container {
  display: flex;
  justify-content: center;
  width: 100%;

  @include md-view {
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
