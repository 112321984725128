<template>
  <div>
    <p class="fs-3 fw-bold mb-4">
      <i class="fa-solid fa-pen-nib me-2" />
      新規記事登録
    </p>
    <div class="row" v-if="helper.master.labels">
      <div class="col-xl-8 col-lg-7 col-12">
        <form>
          <!-- タイトル -->
          <dl>
            <dt class="form-label">
              記事タイトル
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'記事のタイトルとして表示されます。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                @input="changeTitle"
                v-model="title">
            </dd>
          </dl>

          <!-- slug -->
          <dl>
            <dt class="form-label">
              記事slug
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'URLに設定される任意の文字列です。デフォルトは記事タイトルが設定されます。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="slug">
            </dd>
          </dl>

          <!-- 概要 -->
          <dl>
            <dt class="form-label">
              記事概要
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'記事の概要として、一覧の部分でも表示されます。'" />
            </dt>
            <dd>
              <textarea
                class="form-control form-control-md"
                name="summary"
                rows="3"
                v-model="summary"></textarea>
            </dd>
          </dl>

          <!-- サムネイル -->
          <dl>
            <dt class="form-label">
              サムネイル
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'記事のサムネイルとして一覧・詳細ページに表示されます。'" />
            </dt>
            <dd>
              <div class="mt-2">
                <label
                  for="thumbnail"
                  v-on:click="openImageLibrary('thumbnail', false)">
                  <div
                    v-if="thumbnail.s3"
                    class="preview_L preview"
                    :style="`background-image: url(${thumbnail.s3})`"/>
                  <div
                    v-else
                    class="preview_L preview input_btn"/>
                </label>
                <div>
                  <div
                    class="btn square bdr"
                    v-if="thumbnail.s3"
                    v-on:click="deleteFile('thumbnail')">削除する</div>
                </div>
              </div>
            </dd>
          </dl>

          <!-- 詳細 -->
          <dl>
            <dt class="form-label">記事詳細
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'記事本文です。テキストファイルを読み込んだ場合、“##本文“以下の部分が入ります。'" />
            </dt>
            <dd>
              <div
                v-on:click="openImageLibrary('editor', true)"
                class="btn btn-outline-secondary btn-sm mb-2">編集中のエディタに画像を挿入する</div>
              <Editor
                name="content"
                id="content"
                v-model="content"
                placeholder="記事詳細を入力してください。"
                v-bind:init="editorConf"
              />
              <input
                type="file"
                name="file"
                accept=".jpg, .jpeg, .png, .gif"
                id="input-file"
                class="hidden">
            </dd>
          </dl>

          <!-- ギャラリー -->
          <dl>
            <dt class="form-label">ギャラリー</dt>
            <dd>
              <div class="mt-1 mb-3">
                <p class="input_assistant">タイトル</p>
                <input
                  type="text"
                  class="form-control form-control-md"
                  v-model="gallery.title">
              </div>
              <ul
                class="gallery mt-2">
                <li
                  v-for="(row, i) in gallery.medias"
                  :key="row">
                  <div
                    class="preview preview_S"
                    :style="`background-image: url(${row.url})`"/>
                  <div
                    class="delete"
                    v-on:click="deleteFile('gallery', i)"/>
                </li>
                <li>
                  <label for="gallery">
                    <div
                      class="preview preview_S input_btn"
                      v-on:click="openImageLibrary('gallery', true)"/>
                  </label>
                </li>
              </ul>
            </dd>
          </dl>
        </form>
      </div>

      <div class="col-xl-4 col-lg-5 col-12 md-center cards">
        <div class="card">
          <div class="card-header">
            <p class="fw-bold">公開設定</p>
          </div>
          <div class="card-body">
            <v-date-picker
              v-if="user.role && user.role.role > 3"
              class="side-box_datepicker mt-2"
              v-model="published_at"
              mode="dateTime"
              :minute-increment="15"
              is24hr
              :masks='{
                title: "YYYY年 MM月",
              }'/>
            <p
              class="mt-3"
              v-if="published_at && user.role && user.role.role > 3">公開日時：{{ formatTimestamp(published_at, 'YYYY年MM月DD日 HH:mm') }}</p>
            <ul class="mt-3">
              <!-- 公開・予約は管理者のみ -->
              <li v-if="user.role && user.role.role > 4">
                <div
                  v-if="!isScheduled"
                  v-on:click="next(1)"
                  class="btn btn-primary btn-sm">いますぐ公開する</div>
                  <Spacer :y="1"/>
              </li>
              <li v-if="user.role && user.role.role > 4">
                <div
                  v-if="isScheduled"
                  v-on:click="published_at ? next(2) : ''"
                  class="btn btn-primary btn-sm"
                  :class="{ disabled : !published_at }">公開予約する</div>
                <Spacer :y="1"/>
              </li>
              <li v-if="user.role && user.role.role < 5">
                <div
                  v-on:click="next(3)"
                  class="btn btn-primary btn-sm">承認申請する</div>
                <Spacer :y="1"/>
              </li>
              <li>
                <div
                  class="btn btn-outline-secondary btn-sm"
                  v-on:click="next(10)">下書き保存</div>
              </li>
            </ul>
          </div>
        </div>

        <!-- カテゴリー設定 -->
        <!-- <div class="card">
          <p class="card-header fw-bold">カテゴリー</p>
          <div class="card-body">
            <div
              class="form-select"
              v-on:click="flag.showCategories = !flag.showCategories">
              {{ selectedCategory.label }}
            </div>
            <ul
              class="select-list mt-2 category"
              v-show="flag.showCategories">
              <li>
                <input
                  type="radio"
                  name="category"
                  id="category-0"
                  v-model="selectedCategoryId"
                  :value="0">
                <label
                  for="category-0"
                  class="first"
                  :class="{ selected : selectedCategoryId === 0 }"
                  v-on:click="flag.showCategories = false">カテゴリーなし</label>
              </li>
              <li
                v-for="(parent, i) in categories"
                :key="parent.id">
                <input
                  type="radio"
                  name="category"
                  :id="`category-${parent.id}`"
                  v-model="selectedCategoryId"
                  :value="parent.id">
                <label
                  :for="`category-${parent.id}`"
                  :class="{ selected : selectedCategoryId === parent.id }"
                  v-on:click="flag.showCategories = false">{{ parent.label }}</label>
                <ul class="child">
                  <li
                    v-for="(child, j) in parent.children"
                    :key="child.id">
                    <input
                      type="radio"
                      name="category"
                      :id="`category-${child.id}`"
                      v-model="selectedCategoryId"
                      :value="child.id">
                    <label
                      :for="`category-${child.id}`"
                      :class="[
                        categories.length - 1 === i && parent.children.length - 1 === j ? 'last' : '',
                        selectedCategoryId === child.id ? 'selected' : '',
                      ]"
                      v-on:click="flag.showCategories = false">{{ child.label }}</label>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="mt-3">
              <div
                class="btn btn-outline-primary btn-sm"
                v-on:click="changeInputFlag('category')">新規カテゴリー追加</div>
              <div
                class="card mt-3"
                v-if="flag.showInput.category">
                <div class="card-body">
                  <ul>
                    <li
                      v-for="item in categoryItems"
                      :key="item"
                      class="mb-2">
                      <p class="form-label">{{ item.label }}
                        <guide-icon
                          v-if="item.info"
                          :icon="'fa-regular fa-circle-info'"
                          :on-hover-text="item.info"
                          :box-size="150" />
                      </p>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-if="item.name !== 'parent_id'"
                        :id="item.name"
                        v-model="item.value">
                      <select
                        class="form-select form-select-sm"
                        name="parent" id="parent"
                        v-model="item.value"
                        v-if="item.name === 'parent_id'">
                        <option :value="0">親カテゴリとして登録する</option>
                        <option
                          v-for="parent in categories"
                          :key="parent.id"
                          :value="parent.id">{{ parent.label }}</option>
                      </select>
                    </li>
                  </ul>
                  <div class="mt-2">
                    <div
                      class="btn btn-primary btn-sm me-2"
                      v-on:click="registItem('category')">追加</div>
                    <div
                      class="btn btn-outline-secondary btn-sm"
                      v-on:click="cancelInput('category')">キャンセル</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- タグ設定 -->
        <!-- <div class="card">
          <p class="card-header fw-bold">タグ</p>
          <div class="card-body">
            <div class="card">
              <ul class="card-body tag-list tag-selected">
                <li
                  v-for="(tag, i) in selectedTags"
                  :key="tag.id">
                  <p>
                    <i
                      class="fa-regular fa-circle-xmark"
                      v-on:click="deleteTag(i)"></i>
                    #{{ tag.label }}
                  </p>
                </li>
              </ul>
            </div>
            <div class="tag-search mt-2">
              <input
                type="text"
                class="form-control form-control-sm me-2"
                placeholder="タグ名で検索"
                v-model="tagSearchWord"
                @keypress.enter="getTags('search')">
              <div
                class="btn btn-sm btn-dark"
                v-on:click="getTags('search')">検索</div>
            </div>
            <div class="mt-2">
              <div
                class="card"
                v-if="flag.showSearchedTags">
                <ul
                  class="card-body tag-list">
                  <li
                    v-for="(tag, i) in searchedTags"
                    :key="tag.id">
                    <span
                      v-on:click="addTag(tag)"
                      :class="decideFontSize(i, 'searchedTags')"> #{{ tag.label }}</span>
                  </li>
                  <p v-if="!searchedTags.length">検索結果がありませんでした</p>
                </ul>
              </div>
            </div>
            <div class="mt-3">
              <div
                class="btn btn-outline-primary btn-sm"
                v-on:click="changeFlag('showCommonUsedTags')">よく使われているタグから選ぶ</div>
              <div
                class="card mt-2"
                v-if="flag.showCommonUsedTags">
                <ul
                  class="card-body tag-list">
                  <li
                    v-for="(tag, i) in commonUsedTags"
                    :key="tag.id">
                    <span
                    v-on:click="addTag(tag)"
                    :class="decideFontSize(i, 'commonUsedTags')"> #{{ tag.label }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-3">
              <div
                class="btn btn-outline-primary btn-sm"
                v-on:click="changeInputFlag('tag')">新規タグ追加</div>
              <div
                class="card mt-3"
                v-if="flag.showInput.tag">
                <div class="card-body">
                  <ul>
                    <li
                      v-for="item in tagItems"
                      :key="item"
                      class="mb-2">
                      <div class="form-label">{{item.label}}
                        <guide-icon
                          :icon="'fa-regular fa-circle-info'"
                          :on-hover-text="item.info"
                          :box-size="150" />
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        :id="item.name"
                        v-model="item.value">
                    </li>
                  </ul>
                  <div class="mt-2">
                    <div
                      class="btn btn-primary btn-sm me-2"
                      v-on:click="registItem('tag')">追加</div>
                    <div
                      class="btn btn-outline-secondary btn-sm"
                      v-on:click="cancelInput('tag')">キャンセル</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import cf from '@/mixins/commonFunctions.js';
import GuideIcon from '@/components/GuideIcon.vue';
import pa from '@/mixins/postArticle.js';
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'Admin-article-create',
  mixins: [cf],
  components: {
    Editor,
    GuideIcon,
    Spacer,
  },
  data() {
    return {
      flag: {
        showCalendar: false,
        showInput: {
          tag: false,
          category: false,
        },
      },
      title: null,
      slug: null,
      summary: null,
      content: null,
      published_at: null,
      thumbnail: {
        media_id: null,
        s3: null,
      },
      gallery: {
        title: null,
        medias: [],
      },
      validate: {
        size: false,
        view: false,
      },
      files: [],
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        height: 500,
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        toolbar: 'blocks | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image | undo redo | code | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        plugins: 'lists link code table textcolor',
        block_formats: 'Paragraph=p; 見出し１=h1; 見出し２=h2; 見出し３=h3; 見出し４=h4; 見出し５=h5; 見出し６=h6',
      },
    };
  },
  created() {
    this.published_at = moment(new Date()).format('YYYY-MM-DD HH:00:00');
  },
  computed: {
    ...mapState(['helper', 'user']),
    imageData() {
      return this.$store.getters['modal/imageSelect/imageData'];
    },
    isScheduled() {
      if (this.published_at && moment(this.published_at).isAfter(new Date())) {
        return true;
      }
      return false;
    },
  },
  watch: {
    imageData(data) {
      this.insertImage(data);
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 画像を挿入 */
    insertImage(data) {
      if (data.name === 'editor') {
        data.images.forEach((row) => {
          const imgattr = {
            src: row.url,
            style: 'width: 100%;',
            class: 'mce-img',
            longdesc: row.comment,
          };
          const img = getTinymce().activeEditor.dom.createHTML('img', imgattr, '');
          getTinymce().activeEditor.execCommand('mceInsertContent', false, img);
          if (row.comment) {
            const p = getTinymce().activeEditor.dom.createHTML('p', null, row.comment);
            const strong = getTinymce().activeEditor.dom.createHTML('strong', null, p);
            getTinymce().activeEditor.execCommand('mceInsertContent', false, strong);
          }
        });
      } else if (data.name === 'thumbnail') {
        this.thumbnail.s3 = data.images[0].url;
        this.thumbnail.media_id = data.images[0].id;
      } else if (data.name === 'category' || data.name === 'tag') {
        this.og_img[data.name] = data.images[0].url;
      } else if (data.name === 'gallery') {
        data.images.forEach((row) => {
          this.gallery.medias.push({ url: row.url, media_id: row.id });
        });
      }
    },

    /** 登録前準備 */
    next(flag) {
      if (!this.title || this.title === '') return alert('記事タイトルを入力してください。');

      const slug = this.slug ? encodeURIComponent(this.slug) : encodeURIComponent(this.title);

      this.showLoading();
      const thumbnail = this.thumbnail;
      const articleData = {
        user_id: this.user.id,
        flag,
        title: this.title,
        slug,
        summary: this.summary,
        content: this.content,
        published_at: this.published_at,
      };
      const galleryData = this.gallery;
      // const categoryData = { category_id: this.selectedCategoryId };
      // const tagData = {
      //   tags: this.selectedTags,
      //   tagIds: this.selectedTagIds,
      // };
      const data = {
        type: 'create',
        thumbnail,
        articleData,
        galleryData,
        // categoryData,
        // tagData,
      };
      if ([1, 2, 3].includes(flag)) this.registArticle(data);
      else if ([10].includes(flag)) this.saveDraft(data);
    },

    /** 記事登録 */
    async registArticle(data) {
      // 必要項目チェック
      if (!this.content || this.content === '') return alert('記事詳細を入力してください。');

      const result = await pa.registArticle(data);
      if (result.resultType !== 'error') {
        if (data.galleryData.medias.length || (data.galleryData.title && data.galleryData.title !== '') || data.galleryData.id) {
          this.registGallery(result.articleData.id);
        } else {
          this.$router.push('/admin/article/?page=1');
          alert('記事の登録が完了しました。');
          this.hideLoading();
        }
      } else {
        this.hideLoading();
      }
    },

    /** 下書き登録 */
    async saveDraft(data) {
      data.articleData.flag = 10;
      data.env = this.helper.env.name;
      const result = await pa.registArticle(data);
      if (result.resultType !== 'error') {
        if (this.gallery.medias.length || (this.gallery.title && this.gallery.title !== '')) {
          this.registGallery(result.articleData.id);
        } else {
          this.$router.push('/admin/article/?page=1');
          alert('記事の登録が完了しました。');
          this.hideLoading();
        }
      } else {
        this.hideLoading();
      }
    },

    /** ギャラリーの登録 */
    async registGallery(articleId) {
      const data = {
        article_id: articleId,
        galleryData: this.gallery,
      };
      const result = await pa.registGallery(data);
      if (result.resultType !== 'error') {
        this.$router.push('/admin/article/?page=1');
        alert('記事の登録が完了しました。');
      }
      this.hideLoading();
    },

    /** タグ・カテゴリーの登録 */
    async registItem(name) {
      const data = {
        name,
        type: 'register',
        itemData: { flag: 1 },
      };

      let invalid = false;
      this[`${name}Items`].forEach((row) => {
        data.itemData[row.name] = row.value;
        if (row.name !== 'parent_id' && !row.value) invalid = true;
      });
      if (invalid) return alert('未入力の項目があります。');

      if (!data.itemData.label) return alert('ラベルを入力してください。');
      const result = await pa.registItem(data);
      if (result.resultType !== 'error') {
        if (result.exists) return alert('入力されたスラッグは既に使用されているため、利用できません。\n別の名前に変更し、再度お試しください。');
        if (name === 'tag') {
          this.addTag(result.item);
        }
        if (name === 'category') {
          this.selectedCategoryId = result.item.id;
          this.getCategories();
        }
        this.cancelInput(name);
      }
    },

    /** サムネイル・ギャラリーを削除 */
    deleteFile(name, i) {
      if (name === 'thumbnail') {
        this.thumbnail.s3 = null;
        this.thumbnail.media_id = null;
      } else if (name === 'gallery') {
        this.gallery.medias.splice(i, 1);
      }
    },

    /** イメージライブラリーを開く */
    openImageLibrary(name, multiple) {
      const data = {
        name,
        multiple,
      };
      const args = {
        modalName: 'imageLibrary',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    changeTitle() {
      this.slug = this.title;
    },
  },
};
</script>
