import moment from 'moment';
import axios from '@/plugins/axios';

export default {
  async registArticle(params) {
    let result = params;
    result = await this.registBaseData(result);
    if (result.resultType !== 'error') result = await this.registThumbnail(result);
    // if (result.resultType !== 'error' && result.categoryData.category_id !== null) result = await this.registCategoryRelation(result);
    // if (result.resultType !== 'error' && result.tagData.tagIds.length) result = await this.registTagRelation(result);
    return result;
  },

  // OK
  async registBaseData(params) {
    let result = params;
    const data = params.articleData;
    if (!data.published_at) data.published_at = moment(new Date()).format('YYYY-MM-DD HH:mm:00');
    else data.published_at = moment(params.articleData.published_at).format('YYYY-MM-DD HH:mm:00');
    await axios({
      method: 'POST',
      url: `/v1/article/${params.type}`,
      data,
    })
      .then((response) => {
        result.articleData.id = response.data.articleId;
        result.resultType = 'success';
        if (response.data && response.data.exists) {
          result.resultType = 'error';
          return alert('記事のslugが重複しています。\n異なるものを設定してください。');
        }
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        if (error.message) console.log(error.message);
        else console.log(error);
      });

    return result;
  },

  // OK?
  async registThumbnail(params) {
    let result = params;
    const data = {
      foreign_type: 11,
      foreign_id: params.articleData.id,
      media_id: params.thumbnail.media_id,
      is_primary: 1,
    };
    axios({
      method: 'POST',
      url: '/v1/mediaRelation/create',
      data,
    })
      .then(() => {
        result.resultType = 'success';
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        if (error.message) console.log(error.message);
        else console.log(error);
      });
    return result;
  },

  async registGallery(params) {
    let result = params;
    result = await this.registGalleryData(result);
    if (result.resultType !== 'error' && params.article_id && result.type !== 'updater') await this.registGalleryRelation(result);
    return result;
  },

  async registGalleryData(params) {
    let result = params;
    const data = params.galleryData;
    let endpoint = 'create';
    if (params.galleryData.id) {
      data.id = params.galleryData.id;
      endpoint = 'update';
      params.type = 'update';
    }

    await axios({
      method: 'POST',
      url: `/v1/gallery/${endpoint}`,
      data,
    })
      .then((response) => {
        result.resultType = 'success';
        result.gallery_id = response.data.galleryId;
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        alert('ギャラリーの登録に失敗しました。');
        if (error.message) console.log(error.message);
        else console.log(error);
      });
    return result;
  },

  async registGalleryRelation(params) {
    let result = params;
    const data = {
      post_type: 1,
      post_id: params.article_id,
      gallery_id: params.gallery_id,
    };
    await axios({
      method: 'POST',
      url: '/v1/postGallery/set/relation',
      data,
    })
      .then(() => {
        result.resultType = 'success';
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        alert('ギャラリーの登録に失敗しました。');
        if (error.message) console.log(error.message);
        else console.log(error);
      });
    return result;
  },

  // async registCategoryRelation(params) {
  //   let result = params;
  //   const data = {
  //     article_id: params.articleData.id,
  //     category_id: params.categoryData.category_id,
  //   };

  //   await axios({
  //     method: 'POST',
  //     url: '/v1/article/set/categoryRelation',
  //     data,
  //   })
  //     .then(() => {
  //       result.resultType = 'success';
  //     })
  //     .catch((error) => {
  //       result = {
  //         resultType: 'error',
  //       };
  //       if (error.message) console.log(error.message);
  //       else console.log(error);
  //     });
  //   return result;
  // },

  // async registTagRelation(params) {
  //   let result = params;
  //   const data = {
  //     article_id: params.articleData.id,
  //     tagIds: params.tagData.tagIds,
  //   };
  //   await axios({
  //     method: 'POST',
  //     url: '/v1/article/set/tagRelation',
  //     data,
  //   })
  //     .then(() => {
  //       result.resultType = 'success';
  //     })
  //     .catch((error) => {
  //       result = {
  //         resultType: 'error',
  //       };
  //       if (error.message) console.log(error.message);
  //       else console.log(error);
  //     });
  //   return result;
  // },

  // // カテゴリー・タグ登録
  // async registItem(params) {
  //   const data = params.itemData;
  //   const result = {};
  //   await axios({
  //     method: 'POST',
  //     url: `/v1/${params.name}/set/${params.type}`,
  //     data,
  //   })
  //     .then((response) => {
  //       const res = response.data;
  //       result.item = res[`${params.name}`];
  //       result.resultType = 'success';
  //       result.exists = res.exists;
  //     })
  //     .catch((error) => {
  //       if (error.message) console.log(error.message);
  //       else console.log(error);
  //       result.resultType = 'error';
  //     });

  //   return result;
  // },
};
