<template>
  <div>
    <p class="fs-3 fw-bold mb-4">
      <i class="fa-solid fa-party-horn me-2" />
      新規イベント登録
    </p>
    <div class="row" v-if="helper.master.labels">
      <div class="col-xl-8 col-lg-7 col-12">
        <form>

          <!-- タイトル -->
          <dl>
            <dt class="form-label">
              イベントタイトル
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベントのタイトルとして表示されます。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                @input="changeTitle"
                v-model="title">
            </dd>
          </dl>

          <!-- slug -->
          <dl>
            <dt class="form-label">
              イベントslug
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'URLに設定される任意の文字列です。デフォルトはイベントタイトルが設定されます。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="slug">
            </dd>
          </dl>

          <!-- 概要 -->
          <dl>
            <dt class="form-label">
              イベント概要
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベントの概要として、一覧のイベントページでも表示されます。'" />
            </dt>
            <dd>
              <textarea
                class="form-control form-control-md"
                name="summary"
                rows="3"
                v-model="summary"></textarea>
            </dd>
          </dl>

          <!-- サムネイル -->
          <dl>
            <dt class="form-label">
              サムネイル
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベントのサムネイルとして一覧・詳細ページに表示されます。'" />
            </dt>
            <dd>
              <div class="mt-2">
                <label
                  for="thumbnail"
                  v-on:click="openImageLibrary('thumbnail', false)">
                  <div
                    v-if="thumbnail.s3"
                    class="preview_L preview"
                    :style="`background-image: url(${thumbnail.s3})`"/>
                  <div
                    v-else
                    class="preview_L preview input_btn"/>
                </label>
                <div>
                  <div
                    class="btn square bdr"
                    v-if="thumbnail.s3"
                    v-on:click="deleteFile('thumbnail')">削除する</div>
                </div>
              </div>
            </dd>
          </dl>

          <!-- 詳細 -->
          <dl>
            <dt class="form-label">イベント詳細
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベント本文です。テキストファイルを読み込んだ場合、“##本文“以下の部分が入ります。'" />
            </dt>
            <dd>
              <div
                v-on:click="openImageLibrary('editor', true)"
                class="btn btn-outline-secondary btn-sm mb-2">編集中のエディタに画像を挿入する</div>
              <Editor
                name="content"
                id="content"
                v-model="content"
                placeholder="イベント詳細を入力してください。"
                v-bind:init="editorConf"
              />
              <input
                type="file"
                name="file"
                accept=".jpg, .jpeg, .png, .gif"
                id="input-file"
                class="hidden">
            </dd>
          </dl>


          <!-- ギャラリー 保留中 -->
          <dl>
            <dt class="form-label">ギャラリー</dt>
            <dd>
              <div class="mt-1 mb-3">
                <p class="input_assistant">タイトル</p>
                <input
                  type="text"
                  class="form-control form-control-md"
                  v-model="gallery.title">
              </div>
              <ul
                class="gallery mt-2">
                <li
                  v-for="(row, i) in gallery.images"
                  :key="row">
                  <div
                    class="preview preview_S"
                    :style="`background-image: url(${row.url})`"/>
                  <div
                    class="delete"
                    v-on:click="deleteFile('gallery', i)"/>
                </li>
                <li>
                  <label for="gallery">
                    <div
                      class="preview preview_S input_btn"
                      v-on:click="openImageLibrary('gallery', true)"/>
                  </label>
                </li>
              </ul>
            </dd>
          </dl>
        </form>
      </div>

      <div class="col-xl-4 col-lg-5 col-12 md-center cards">
        <div class="card">
          <div class="card-header">
            <p class="fw-bold">公開設定</p>
          </div>
          <div class="card-body">
            <v-date-picker
              v-if="user.role && user.role.role > 3"
              class="side-box_datepicker mt-2"
              v-model="published_at"
              mode="dateTime"
              :minute-increment="15"
              is24hr
              :masks='{
                title: "YYYY年 MM月",
              }'/>
            <p
              class="mt-3"
              v-if="published_at && user.role && user.role.role > 3">公開日時：{{ formatTimestamp(published_at, 'YYYY年MM月DD日 HH:mm') }}</p>
            <ul class="mt-3">
              <!-- 公開・予約は管理者のみ -->
              <li v-if="user.role && user.role.role > 4">
                <div
                  v-if="!isScheduled"
                  v-on:click="next(1)"
                  class="btn btn-primary btn-sm">いますぐ公開する</div>
                  <Spacer :y="1"/>
              </li>
              <li v-if="user.role && user.role.role > 4">
                <div
                  v-if="isScheduled"
                  v-on:click="published_at ? next(2) : ''"
                  class="btn btn-primary btn-sm"
                  :class="{ disabled : !published_at }">公開予約する</div>
                <Spacer :y="1"/>
              </li>
              <li v-if="user.role && user.role.role < 5">
                <div
                  v-on:click="next(3)"
                  class="btn btn-primary btn-sm">承認申請する</div>
                <Spacer :y="1"/>
              </li>
              <li>
                <div
                  class="btn btn-outline-secondary btn-sm"
                  v-on:click="next(10)">下書き保存</div>
              </li>
            </ul>
          </div>
        </div>
        <Spacer :y="2"/>
        <div class="card">
          <div class="card-header">
            <p class="fw-bold">開催設定</p>
          </div>
          <div class="card-body">
            <v-date-picker
              v-if="user.role && user.role.role > 3"
              class="side-box_datepicker mt-2"
              v-model="event_date"
              mode="dateTime"
              :minute-increment="15"
              is24hr
              :masks='{
                title: "YYYY年 MM月",
              }'/>
            <p
              class="mt-3"
              v-if="event_date && user.role && user.role.role > 3">開催日時：{{ formatTimestamp(event_date, 'YYYY年MM月DD日 HH:mm') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import cf from '@/mixins/commonFunctions.js';
import GuideIcon from '@/components/GuideIcon.vue';
import pe from '@/mixins/postEvent.js';
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'Admin-event-create',
  mixins: [cf],
  components: {
    Editor,
    GuideIcon,
    Spacer,
  },
  data() {
    return {
      flag: {
        showCalendar: false,
        showInput: {
          tag: false,
          category: false,
        },
      },
      title: null,
      slug: null,
      summary: null,
      content: null,
      published_at: null,
      event_date: null,
      thumbnail: {
        media_id: null,
        s3: null,
      },
      gallery: {
        title: null,
        medias: [],
      },
      validate: {
        size: false,
        view: false,
      },
      files: [],
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        height: 500,
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        toolbar: 'blocks | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image | undo redo | code | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        plugins: 'lists link code table textcolor',
        block_formats: 'Paragraph=p; 見出し１=h1; 見出し２=h2; 見出し３=h3; 見出し４=h4; 見出し５=h5; 見出し６=h6',
      },
    };
  },
  created() {
    this.published_at = moment(new Date()).format('YYYY-MM-DD HH:00:00');
    this.event_date = moment(new Date()).format('YYYY-MM-DD HH:00:00');
  },
  computed: {
    ...mapState(['helper', 'user']),
    imageData() {
      return this.$store.getters['modal/imageSelect/imageData'];
    },
    isScheduled() {
      if (this.published_at && moment(this.published_at).isAfter(new Date())) {
        return true;
      }
      return false;
    },
  },
  watch: {
    imageData(data) {
      this.insertImage(data);
    },
  },
  methods: {
    hover(bool, idx, type) {
      this[`${type}Items`][idx].hover = bool;
    },

    insertImage(data) {
      if (data.name === 'editor') {
        data.images.forEach((row) => {
          const imgattr = {
            src: row.url,
            style: 'width: 100%;',
            class: 'mce-img',
            longdesc: row.comment,
          };
          const img = getTinymce().activeEditor.dom.createHTML('img', imgattr, '');
          getTinymce().activeEditor.execCommand('mceInsertContent', false, img);
          if (row.comment) {
            const p = getTinymce().activeEditor.dom.createHTML('p', null, row.comment);
            const strong = getTinymce().activeEditor.dom.createHTML('strong', null, p);
            getTinymce().activeEditor.execCommand('mceInsertContent', false, strong);
          }
        });
      } else if (data.name === 'thumbnail') {
        this.thumbnail.s3 = data.images[0].url;
        this.thumbnail.media_id = data.images[0].id;
      } else if (data.name === 'category' || data.name === 'tag') {
        this.og_img[data.name] = data.images[0].url;
      } else if (data.name === 'gallery') {
        data.images.forEach((row) => {
          this.gallery.medias.push({ url: row.url, media_id: row.id });
        });
      }
    },

    next(flag) {
      if (!this.title || this.title === '') return alert('イベントタイトルを入力してください。');

      const slug = this.slug ? encodeURIComponent(this.slug) : encodeURIComponent(this.title);

      const thumbnail = this.thumbnail;
      const eventData = {
        user_id: this.user.id,
        flag,
        slug,
        title: this.title,
        summary: this.summary,
        content: this.content,
        published_at: this.published_at,
        event_date: this.event_date,
      };
      const galleryData = this.gallery;
      // const categoryData = { category_id: this.selectedCategoryId };
      // const tagData = {
      //   tags: this.selectedTags,
      //   tagIds: this.selectedTagIds,
      // };
      const data = {
        type: 'create',
        thumbnail,
        eventData,
        galleryData,
        // categoryData,
        // tagData,
      };
      if ([1, 2, 3].includes(flag)) this.registEvent(data);
      else if ([10].includes(flag)) this.saveDraft(data);
    },

    async registEvent(data) {
      // 必要項目チェック
      if (!this.content || this.content === '') return alert('イベント詳細を入力してください。');

      const result = await pe.registEvent(data);
      if (result.resultType !== 'error') {
        if (data.galleryData.medias.length || (data.galleryData.title && data.galleryData.title !== '') || data.galleryData.id) {
          this.registGallery(result.eventData.id);
        } else {
          this.$router.push('/admin/event/?page=1');
          alert('イベントの登録が完了しました。');
        }
      }
    },

    async saveDraft(data) {
      data.eventData.flag = 10;
      data.env = this.helper.env.name;
      const result = await pe.registEvent(data);
      if (result.resultType !== 'error') {
        if (this.gallery.medias.length || (this.gallery.title && this.gallery.title !== '')) {
          this.registGallery(result.eventData.id);
        } else {
          this.$router.push('/admin/event/?page=1');
          alert('イベントの登録が完了しました。');
        }
      }
    },

    async registGallery(eventId) {
      const data = {
        event_id: eventId,
        galleryData: this.gallery,
      };
      const result = await pe.registGallery(data);
      if (result.resultType !== 'error') {
        this.$router.push('/admin/event/?page=1');
        alert('イベントの登録が完了しました。');
      }
    },

    async registItem(name) {
      const data = {
        name,
        type: 'register',
        itemData: { flag: 1 },
      };

      let invalid = false;
      this[`${name}Items`].forEach((row) => {
        data.itemData[row.name] = row.value;
        if (row.name !== 'parent_id' && !row.value) invalid = true;
      });
      if (invalid) return alert('未入力の項目があります。');

      if (!data.itemData.label) return alert('ラベルを入力してください。');
      const result = await pe.registItem(data);
      if (result.resultType !== 'error') {
        if (result.exists) return alert('入力されたスラッグは既に使用されているため、利用できません。\n別の名前に変更し、再度お試しください。');
        if (name === 'tag') {
          this.addTag(result.item);
        }
        if (name === 'category') {
          this.selectedCategoryId = result.item.id;
          this.getCategories();
        }
        this.cancelInput(name);
      }
    },

    /** サムネイル・ギャラリーを削除 */
    deleteFile(name, i) {
      if (name === 'thumbnail') {
        this.thumbnail.s3 = null;
        this.thumbnail.media_id = null;
      } else if (name === 'gallery') {
        this.gallery.medias.splice(i, 1);
      }
    },

    /** イメージライブラリーを開く */
    openImageLibrary(name, multiple) {
      const data = {
        name,
        multiple,
      };
      const args = {
        modalName: 'imageLibrary',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    changeTitle() {
      this.slug = this.title;
    },
  },
};
</script>
