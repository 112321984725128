<template>
  <div>
    <p class="fs-3 fw-bold mb-4"><i class="fa-solid fa-party-horn me-2" />イベント編集</p>

    <Loading
      v-if="flag.isLoading"
      size="sm" />
    <div
      class="row"
      v-if="helper.master.labels && !flag.isLoading">
      <div
        class="col-xl-8 col-lg-7 col-12"
        v-if="event">
        <form v-on:submit.prevent>
          <!-- 記事詳細url -->
          <dl v-if="event.flag === 1">
            <dt class="form-label">イベントURL</dt>
            <dd>
              <a
                class="text-break"
                :href="`${prefix}/event/${event.slug}/`"
                target="blank">{{ prefix }}/event/{{ decodeURIComponent(event.slug) }}/</a>
            </dd>
          </dl>

          <!-- タイトル -->
          <dl>
            <dt class="form-label">イベントタイトル
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベントのタイトルとして表示されます。'" />
              </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                :disabled="flag.isConfirm"
                v-model="title">
            </dd>
          </dl>

          <!-- slug -->
          <dl>
            <dt class="form-label">
              イベントslug
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'URLに設定される任意の文字列です。デフォルトはイベントタイトルが設定されます。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="slug">
            </dd>
          </dl>

          <!-- 概要 -->
          <dl>
            <dt class="form-label">イベント概要
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベントの概要として、一覧の部分でも表示されます。'" />
            </dt>
            <dd>
              <textarea
                class="form-control form-control"
                name="summary"
                rows="3"
                v-model="summary"></textarea>
            </dd>
          </dl>

          <!-- サムネイル -->
          <dl>
            <dt class="form-label">
              サムネイル
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'記事のサムネイルとして一覧・詳細ページに表示されます。'" />
            </dt>
            <dd>
              <div class="mt-2">
                <label
                  for="thumbnail"
                  v-on:click="openImageLibrary('thumbnail', false)">
                  <div
                    v-if="thumbnail.s3"
                    class="preview_L preview"
                    :style="`background-image: url(${thumbnail.s3})`"/>
                  <div
                    v-else
                    class="preview_L preview input_btn"/>
                </label>
                <div>
                  <div
                    class="btn square bdr"
                    v-if="thumbnail.s3"
                    v-on:click="deleteFile('thumbnail')">削除する</div>
                </div>
              </div>
            </dd>
          </dl>

          <!-- 詳細 -->
          <dl>
            <dt class="form-label">イベント詳細
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'イベント本文です。'" />
            </dt>
            <dd>
              <div
                v-on:click="openImageLibrary('editor', true)"
                class="btn btn-outline-secondary btn-sm mb-2">編集中のエディタに画像を挿入する</div>
              <Editor
                name="content"
                id="content"
                v-model="content"
                placeholder="イベント詳細を入力してください。"
                :disabled="flag.isConfirm"
                v-bind:init="editorConf"
              />
              <input
                type="file"
                name="file"
                accept=".jpg, .jpeg, .png, .gif"
                id="input-file"
                class="hidden">
            </dd>
          </dl>
          <!-- ギャラリー -->
          <dl>
            <dt class="form-label">ギャラリー</dt>
            <dd>
              <div class="mt-1 mb-3">
                <p class="input_assistant">タイトル</p>
                <input
                  type="text"
                  class="form-control form-control-md"
                  v-model="gallery.title">
              </div>
              <ul
                class="gallery mt-2">
                <li
                  v-for="(row, i) in gallery.medias"
                  :key="row">
                  <div
                    class="preview preview_S"
                    :style="`background-image: url(${row.url})`"/>
                  <div
                    class="delete"
                    v-on:click="deleteFile('gallery', i)"/>
                </li>
                <li>
                  <label for="gallery">
                    <div
                      class="preview preview_S input_btn"
                      v-on:click="openImageLibrary('gallery', true)"/>
                  </label>
                </li>
              </ul>
            </dd>
          </dl>

        </form>
      </div>

      <div class="col-xl-4 col-lg-5 col-12 md-center">
        <div class="card">
          <p class="card-header fw-bold">公開設定</p>
          <div class="card-body">
            <v-date-picker
              v-if="user.role.role > 3"
              class="side-box_datepicker mt-2"
              v-model="published_at"
              mode="dateTime"
              :minute-increment="15"
              is24hr
              :masks='{
                title: "YYYY年 MM月",
              }'/>
            <p
              class="mt-3"
              v-if="published_at && user.role.role > 3">公開日時：{{ formatTimestamp(published_at, 'YYYY年MM月DD日 HH:mm') }}</p>
            <ul class="mt-3">
              <!-- 公開・予約は管理者のみ -->
              <li v-if="user.role.role > 4 && !isScheduled">
                <div
                  v-on:click="next(1)"
                  class="btn btn-primary btn-sm">いますぐ公開する</div>
                <Spacer :y="1"/>
              </li>
              <li v-if="user.role.role > 4 && isScheduled">
                <div
                  v-on:click="published_at ? next(2) : ''"
                  class="btn btn-primary btn-sm"
                  :class="{ disabled : !published_at }">公開予約する</div>
                <Spacer :y="1"/>
              </li>
              <li v-if="user.role.role < 5">
                <div
                  v-on:click="next(3)"
                  class="btn btn-primary btn-sm">承認申請する</div>
                <Spacer :y="1"/>
              </li>
              <li>
                <div
                  class="btn btn-outline-secondary btn-sm"
                  v-on:click="next(10)">下書き保存</div>
              </li>
            </ul>
          </div>
        </div>
        <Spacer :y="2"/>
        <div class="card">
          <div class="card-header">
            <p class="fw-bold">開催設定</p>
          </div>
          <div class="card-body">
            <v-date-picker
              v-if="user.role.role > 3"
              class="side-box_datepicker mt-2"
              v-model="event_date"
              mode="dateTime"
              :minute-increment="15"
              is24hr
              :masks='{
                title: "YYYY年 MM月",
              }'/>
            <p
              class="mt-3"
              v-if="event_date && user.role.role > 3">開催日時：{{ formatTimestamp(event_date, 'YYYY年MM月DD日 HH:mm') }}</p>
          </div>
        </div>

        <div class="d-grid mt-4">
          <div
            class="btn btn-danger"
            v-on:click="deleteEvent">イベントを削除する</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import cf from '@/mixins/commonFunctions.js';
import pe from '@/mixins/postEvent.js';
import GuideIcon from '@/components/GuideIcon.vue';
import Loading from '@/components/Loading.vue';
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'Admin-event-update',
  mixins: [cf, pe],
  components: {
    Editor,
    GuideIcon,
    Loading,
    Spacer,
  },
  data() {
    return {
      slug: null,
      event: null,
      flag: {
        isLoading: true,
        isConfirm: false,
        showCalendar: false,
        showInput: {
          tag: false,
          category: false,
        },
      },
      setDataItem: ['title', 'summary', 'content', 'published_at', 'event_date'],
      title: null,
      summary: null,
      content: null,
      published_at: null,
      thumbnail: {
        s3: null,
        media_id: null,
      },
      gallery: {
        title: null,
        medias: [],
      },
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        height: 500,
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        toolbar: 'blocks | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image | undo redo | code | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        plugins: 'lists link code table textcolor',
        block_formats: 'Paragraph=p; 見出し１=h1; 見出し２=h2; 見出し３=h3; 見出し４=h4; 見出し５=h5; 見出し６=h6',
      },
    };
  },
  created() {
    this.slug = decodeURIComponent(this.$route.params.slug);
    if (this.user.email) {
      this.getEvent();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getEvent();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    imageData() {
      return this.$store.getters['modal/imageSelect/imageData'];
    },
    prefix() {
      let str = 'https://kiryu.city';
      if (this.helper.env.name === 'develop') str = 'https://dev.kiryu.city';
      if (this.helper.env.name === 'local') str = 'http://localhost:6080';
      return str;
    },

    isScheduled() {
      if (this.published_at && moment(this.published_at).isAfter(new Date())) {
        return true;
      }
      return false;
    },
  },
  watch: {
    imageData(data) {
      this.insertImage(data);
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    hover(bool, idx, type) {
      this[`${type}Items`][idx].hover = bool;
    },

    insertImage(data) {
      if (data.name === 'editor') {
        data.images.forEach((row) => {
          const imgattr = {
            src: row.url,
            style: 'width: 100%;',
            class: 'mce-img',
            longdesc: row.comment,
          };
          const img = getTinymce().activeEditor.dom.createHTML('img', imgattr, '');
          getTinymce().activeEditor.execCommand('mceInsertContent', false, img);
          if (row.comment) {
            const p = getTinymce().activeEditor.dom.createHTML('p', null, row.comment);
            const strong = getTinymce().activeEditor.dom.createHTML('strong', null, p);
            getTinymce().activeEditor.execCommand('mceInsertContent', false, strong);
          }
        });
      } else if (data.name === 'thumbnail') {
        this.thumbnail.s3 = data.images[0].url;
        this.thumbnail.media_id = data.images[0].id;
      } else if (data.name === 'gallery') {
        data.images.forEach((row) => {
          this.gallery.medias.push({ url: row.url, media_id: row.id });
        });
      }
    },

    getEvent() {
      this.flag.isLoading = true;

      const params = {
        slug: encodeURIComponent(this.slug),
        flags: [1, 2, 3, 10],
      };
      this.axios({
        method: 'GET',
        url: '/v1/event/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (!res.detail) {
            alert('このイベントは削除済みです。');
            this.$router.push('/admin/event/?page=1');
            return;
          }
          this.event = res.detail;
          if (this.event.flag <= 2 && this.user.role.role < 4) {
            alert('公開中のイベントの編集権限がありません。');
            this.$router.push('/admin/event/?page=1');
            return;
          }
          this.setData();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading = false;
        });
    },
    setData() {
      this.setDataItem.forEach((name) => {
        this[name] = this.event[name];
      });
      // サムネイル
      if (this.event.thumbnail && this.event.thumbnail.length) {
        this.thumbnail.s3 = this.event.thumbnail[0].url || null;
        this.thumbnail.media_id = this.event.thumbnail[0].id;
      }
      // ギャラリー
      if (this.event.galleries && this.event.galleries.length) {
        this.gallery.id = this.event.galleries[0].id;
        this.gallery.title = this.event.galleries[0].title;
        if (this.event.galleries[0].urls.length) {
          this.event.galleries[0].urls.forEach((row) => {
            this.gallery.medias.push({
              id: row.id,
              url: row.media ? row.media.url : null,
              media_id: row.media_id,
            });
          });
        }
      }
      // // カテゴリーの紐付け
      // if (this.event.categories && this.event.categories.length) this.selectedCategoryId = this.event.categories[0].id;
      // // タグの紐付け
      // if (this.event.tags && this.event.tags.length) {
      //   this.event.tags.forEach((row) => {
      //     this.selectedTags.push(row);
      //     this.selectedTagIds.push(Number(row.id));
      //   });
      // }
      if (!this.published_at) {
        this.published_at = moment(new Date()).format('YYYY-MM-DD HH:00:00');
      }
      if (!this.event_date) {
        this.event_date = moment(new Date()).format('YYYY-MM-DD HH:00:00');
      }
    },

    next(flag) {
      if (!this.title || this.title === '') return alert('イベントタイトルを入力してください。');

      const slug = this.slug ? encodeURIComponent(this.slug) : encodeURIComponent(this.title);

      this.showLoading();
      const thumbnail = this.thumbnail;
      const eventData = {
        id: this.event.id,
        user_id: this.user.id,
        flag,
        slug,
        title: this.title,
        summary: this.summary,
        content: this.content,
        published_at: this.published_at,
        event_date: this.event_date,
      };
      const galleryData = this.gallery;
      // const categoryData = { category_id: this.selectedCategoryId };
      // const tagData = {
      //   tags: this.selectedTags,
      //   tagIds: this.selectedTagIds,
      // };

      const data = {
        type: 'update',
        thumbnail,
        eventData,
        galleryData,
        // categoryData,
        // tagData,
        // authorData,
      };

      if ([1, 2, 3].includes(flag)) this.registEvent(data);
      else if ([10].includes(flag)) this.saveDraft(data);
    },

    async deleteEvent() {
      if (!confirm('イベントを削除してよろしいですか？')) return;

      const thumbnail = this.thumbnail;
      const eventData = {
        id: this.event.id,
        user_id: this.user.id,
        flag: 999,
        published_at: this.published_at,
      };
      // const categoryData = { category_id: this.selectedCategoryId };
      // const tagData = {
      //   tags: this.selectedTags,
      //   tagIds: this.selectedTagIds,
      // };

      const data = {
        type: 'update',
        thumbnail,
        eventData,
        // categoryData,
        // tagData,
      };
      const result = await pe.registEvent(data);
      if (result.resultType !== 'error') {
        this.$router.push('/admin/event/?page=1');
        alert('イベントを削除しました。');
      }
    },

    async registEvent(data) {
      // 必要項目チェック
      if (!this.content || this.content === '') return alert('イベント詳細を入力してください。');

      const result = await pe.registEvent(data);
      if (result.resultType !== 'error') {
        if (data.galleryData.medias.length || (data.galleryData.title && data.galleryData.title !== '') || data.galleryData.id) {
          this.registGallery(result.eventData.id);
        } else {
          this.$router.push('/admin/event/?page=1');
          alert('イベントの更新が完了しました。');
          this.hideLoading();
        }
      } else {
        this.hideLoading();
      }
    },

    async saveDraft(data) {
      data.eventData.flag = 10;
      data.env = this.helper.env.name;
      const result = await pe.registEvent(data);
      if (result.resultType !== 'error') {
        if (this.gallery.medias.length || (this.gallery.title && this.gallery.title !== '') || this.gallery.id) {
          this.registGallery();
        } else {
          this.$router.push('/admin/event/?page=1');
          alert('イベントの更新が完了しました。');
          this.hideLoading();
        }
      } else {
        this.hideLoading();
      }
    },

    async registGallery() {
      const data = {
        event_id: this.event.id,
        galleryData: this.gallery,
      };
      const result = await pe.registGallery(data);
      if (result.resultType !== 'error') {
        this.$router.push('/admin/event/?page=1');
        alert('イベントの更新が完了しました。');
      }
      this.hideLoading();
    },

    /** サムネイル・ギャラリーを削除 */
    deleteFile(name, i) {
      if (name === 'thumbnail') {
        this.thumbnail.s3 = null;
        this.thumbnail.media_id = null;
      } else if (name === 'gallery') {
        this.gallery.medias.splice(i, 1);
      }
    },

    /** イメージライブラリーを開く */
    openImageLibrary(name, multiple) {
      const data = {
        name,
        multiple,
      };
      const args = {
        modalName: 'imageLibrary',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>
