<template>
  <div :class="[$style.wrap, $style[type]]">
    <div
      v-if="url"
      :class="[$style.icon, $style[type]]"
      :style="`background-image: url(${url})`"/>
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'icon-box',
  props: {
    type: {
      type: String,
      default: 'sm',
      validator: (value) => ['sm', 'lg'].includes(value),
    },
    url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  &.sm {
    align-items: center;
  }
}
.icon {
  background-size: cover;
  background-position: center;
  border-radius: 50%;

  &.lg {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
  &.sm {
    width: 55px;
    height: 55px;
    margin-right: 12px;
  }
}
.content {
  flex: 1;
}

@include sm-view {
  .icon {
    &.lg {
      width: 56px;
      height: 56px;
    }
    &.sm {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
