/**
 * コンテンツ情報
 */

const actions = {
  handleScroll({ state, commit }) {
    // トップ header
    if (state.scroll.top >= state.positions.home.firstView.bottom) {
      commit('changeFlag', { page: 'home', name: 'header', value: true });
    } else if (state.scroll.top < state.positions.home.firstView.bottom) {
      commit('changeFlag', { page: 'home', name: 'header', value: false });
    }
    // トップ about.introduction
    if (state.scroll.bottom >= state.positions.home.introduction.top) {
      commit('changeFlag', { page: 'home', name: 'introduction', value: true });
    } else if (state.scroll.bottom < state.positions.home.introduction.top) {
      commit('changeFlag', { page: 'home', name: 'introduction', value: false });
    }
    // トップ about.snap
    if (state.scroll.bottom >= state.positions.home.snap.top) {
      commit('changeFlag', { page: 'home', name: 'snap', value: true });
    } else if (state.scroll.bottom < state.positions.home.snap.top) {
      commit('changeFlag', { page: 'home', name: 'snap', value: false });
    }
  },

  setData({ commit }, args) {
    commit('setData', args);
  },
};


const mutations = {
  setData(state, args) {
    if (args.type === 'scroll') {
      state[args.type] = args.data;
    } else {
      state[args.type][args.page][args.name] = args.data;
    }
  },

  changeFlag(state, args) {
    state.flag[args.page][args.name] = args.value;
  },
};

const state = {
  scroll: {
    top: null,
    bottom: null,
  },
  positions: {
    home: {
      firstView: null,
      introduction: null,
      snap: null,
    },
  },
  flag: {
    home: {
      header: false,
      introduction: false,
      snap: false,
    },
  },
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
