<template>
  <v-date-picker v-model="date" timezone=""
    :masks="{
      title: 'YYYY年 MM月',
    }">
    <template v-slot="{ inputValue, inputEvents }">
      <input
        class="form-control form-control-sm"
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </v-date-picker>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    name: {
      type: String,
    },
    myDate: {
      type: String,
    },
  },
  data() {
    return {
      timezone: '',
      date: null,
    };
  },
  created() {
    if (this.myDate) this.date = this.myDate;
  },
  watch: {
    date() {
      this.$emit('sendDate', {
        name: this.name,
        date: moment(this.date).format('YYYY-MM-DD'),
      });
    },
  },
};
</script>

