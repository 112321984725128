<template>
  <div :class="$style.kv" ref="kv">
    <div class="container" id="topAnker" :class="$style.wrapper">
      <spacer :y="15"/>
      <div class="image" :class="$style['logo-wrapper']">
        <div class="image-body" :class="$style.logo"></div>
      </div>

      <spacer :y="16" />
      <div :class="$style.slider_container">
        <slider :sliders="slideImages" />
      </div>

      <spacer :y="15"/>
      <p>FunKIRYUを楽しむコンテンツを揃えております。<br>更に追加予定。楽しみにお待ち下さい。</p>

      <spacer :y="5"/>
      <div :class="$style.icons">
        <ul>
          <li
            v-for="row in postTypes"
            :key="row.name">
            <router-link
              class="bg-flow"
              :to="`/${row.name}/`">
              <div class="image">
                <div
                  class="image-body"
                  :class="$style['image-body']"
                  :style="`background-image: url(/img/icons/icon_${row.name}.svg);`"></div>
              </div>
              <dl>
                <dt class="target"><span>{{ row.name.toUpperCase() }}</span></dt>
                <spacer :y=".5" />
                <dd>{{ row.label }}</dd>
              </dl>
            </router-link>

          </li>
        </ul>
      </div>

      <div :class="$style.embed_youtube">
        <div :class="$style.embed_youtube_outer">
          <div :class="$style.embed_youtube_controll">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ldh6Pzu0DVM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div :class="$style.embed_youtube_sp">
        <div :class="$style.embed_youtube_sp_outer">
          <div :class="$style.embed_youtube_sp_controll">
            <iframe
              src="https://www.youtube.com/embed/ldh6Pzu0DVM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer.vue';
import Slider from '@/components/Slider.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Home-firstView',
  mixins: [cf],
  components: {
    Spacer,
    Slider,
  },
  props: {
    postTypes: {
      type: Array,
    },
  },
  data() {
    return {
      slideImages: [
        '/img/article/gazo_20230306_22.jpg',
        '/img/article/gazo_20230206_21.jpg',
        '/img/article/gazo_20221205_20.jpg',
        '/img/article/gazo_20221107_19.jpg',
        '/img/article/gazo_20221003_18.jpg',
      ],
    };
  },
  computed: {
    ...mapState(['helper', 'scroll']),
  },
  created() {
  },
  mounted() {
    this.getAreaPos();
  },
  methods: {
    /** header表示表示のためpositionをstoreへ記録 */
    getAreaPos() {
      const data = this.$refs.kv.getBoundingClientRect();
      const args = {
        type: 'positions',
        page: 'home',
        name: 'firstView',
        data,
      };
      this.$store.dispatch('scroll/setData', args, { root: true });
    },

    /**
     * postTypeによって遷移先をサイト内なのか外部サイトかを判定
     * サイト内リンクであればfalseを返し、外部サイトならURLを返す
     * pageコンテンツ追加に伴い修正する際に漏れをなくすためcfに記載
     */
    getLink(type) {
      const result = cf.getLink(type);
      return result;
    },
  },
};
</script>

<style lang="scss" module>
$mgn: 40px;
.slider_container {
  width: 95%;
}
.kv {
  $size: 100px;
  position: relative;
  background-color: var(--beige);
  margin-bottom: $size;
  // ダミー（実際にはcanvas実装）
  &::after {
    position: absolute;
    width: 100vw;
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/img/dammy/fv_end.svg');
    $height: calc(100vw * (226 / 1440));
    height: $height;
    bottom: calc($height * -1 * 2 / 3);
    z-index: -1;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
}
.logo-wrapper {
  max-width: 837px;
  width: 80%;
  margin: 0 auto;
  .logo {
    padding-top: percentage(582/837);
    background-image: url(/img/logo/basic.svg);
  }
}

.icons {
  .image-body {
    padding-top: 100%;
  }
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > li {
      width: 135px;
      margin: 3%;
      // &:not(:first-child) {
      //   margin-left: $mgn;
      // }
    }
    a {
      line-height: 1.4;
      display: block;
      background-color: rgba(black, .05);
      transition: all .4s;
      &:hover {
        background-color: rgba(black, .1);
      }
    }
    dl {
      padding: 12px 16px;
    }
    dt {
      font-size: 18px;
    }
    dd {
      font-size: 11px;
    }
  }
}

.embed_youtube {
  &_outer {
    margin-top: 23%;
    margin-bottom: 3%;
  }
  &_sp {
    display: none;
  }
}

@include sm-view {
  .kv {
    // ダミー（実際にはcanvas実装）
    &::after {
      $height: calc(100vw * (226 / 1440));
      height: $height;
      bottom: calc($height * -1 * 2 / 3);
      background-size: cover;
      background-position: center;
    }
  }
  .logo-wrapper {
    width: 100%;
    &:before {
      content: "";
      position: relative;
      display: block;
      width: percentage(266/342);
      margin: 0 auto;
      height: 0;
      padding-top: percentage(194/532);
      background: center url(/img/logo/logo_text.png) no-repeat;
      background-size: contain;
    }
    .logo {
      padding-top: percentage(350/678);
      background-position: bottom center;
      background-image: url(/img/logo/logo.png);
    }
  }

  .icons {
    width: 100vw;
    display: flex;
    justify-content: center;
    ul {
      margin: 0 auto;
      padding: 0 24px;
      justify-content: flex-start;
      overflow: scroll;
      > li {
        min-width: 136px;
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }

  .embed_youtube {
    display: none;
    &_sp {
      margin-top: 25%;
      margin-bottom: 5%;
      display: block;
    }
    &_outer {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
    &_controll {
      width: 200px;
      height: 100%;
    }
  }
}
</style>
