/**
 * View情報管理
*/

const actions = {
  // TODO: 定数化
  setDisplaySize({ commit }, windowWidth) {
    if (windowWidth >= 1024) {
      commit('setDisplaySize', 'lg');
    } else if (windowWidth >= 767) {
      commit('setDisplaySize', 'md');
    } else if (windowWidth >= 375) {
      commit('setDisplaySize', 'sm');
    } else {
      commit('setDisplaySize', 'xs');
    }
  },
};

const mutations = {
  setDisplaySize(state, size) {
    state.display_size = size;
  },
  
};

const state = {
  display_size: 'sm',
  show_menu: false,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
