<template>
  <div>
    <ul :class="$style.infolist">
      <li
        v-for="(item, i) in items"
        :key="i">
        <p :class="$style.label" v-if="shop[item.name]"><span>{{ item.label }}</span></p>
        <p :class="$style.value">
          <a :href="`tel:${shop[item.name]}`" v-if="item.name === 'tel'">{{ shop[item.name] }}</a>
          <a :href="shop[item.name]" v-else-if="item.name === 'url'">{{ shop[item.name] }}</a>
          <span v-else>{{ shop[item.name] }}</span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          name: 'addresses',
          label: '住所',
        },
        {
          name: 'tel',
          label: '電話番号',
        },
        {
          name: 'business_hours',
          label: '営業時間',
        },
        {
          name: 'regular_holiday',
          label: '定休日',
        },
        {
          name: 'url',
          label: 'URL',
        },
      ],
    };
  },
  created() {
  },
  methods: {
    adjustAddress(obj) {
      // return `〒${obj.zip} ${obj.pref}${obj.city}${obj.address}${obj.building || ''}`;
      return obj;
    },
  },
};
</script>

<style lang="scss" module>
.infolist {
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
  .label {
    font-size: 12px;
    span {
      padding-bottom: 4px;
      border-bottom: 1px solid rgba(#000, .2);
    }
  }
  .value {
    display: block;
    font-size: 16px;
    display: inline-block;
    margin-top: 8px;
  }
  a {
    color: var(--font-title);
  }
}
</style>
