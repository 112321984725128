<template>
  <div :class="$style.archive">
    <div v-if="postType">
      <div :class="$style['bg-accent']"></div>
      <div class="container">
        <spacer :y="8" />
        <h1
          class="title archive"
          :class="$style[`icon_${postType.name}`]"
          v-if="postType">
          <span>{{ postType.name.toUpperCase() }}</span>
          <small>{{ postType.label }}</small>
        </h1>

        <spacer :y="view.display_size !== 'sm' ? 15 : 8" />
        <div :class="$style.columns" v-if="posts.length">
          <div
            class="pc"
            :class="$style.left"
            v-if="sortedPosts.pc.left.length">
            <card
              v-for="(row, i) in sortedPosts.pc.left"
              :key="row.slug"
              :isSquare="i === 0"
              :isMain="i === 0"
              :postType="postType"
              :data="row" />
          </div>
          <div
            class="pc"
            :class="$style.right"
            v-if="sortedPosts.pc.right.length">
            <card
              v-for="row in sortedPosts.pc.right"
              :key="row.slug"
              :postType="postType"
              :data="row" />
          </div>
        </div>

        <div v-else :class="$style.comingsoon">
          <!-- <p>COMING SOON</p> -->
        </div>

        <Loading v-if="flag.loading" class="pc" />

        <div
          class="sp"
          v-if="sortedPosts.sp.left.length">
          <div :class="$style['sp-posts']">
            <card
              v-for="row in sortedPosts.sp.left"
              :key="row.slug"
              :isSquare="true"
              :isMain="false"
              :postType="postType"
              :data="row"
              :class="$style.item" />
          </div>
        </div>
        <Loading v-if="!sortedPosts.sp.left.length" class="sp" />
      </div>
    </div>
    <Loading v-else :isCenter="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import xml2js from 'xml2js';
import Spacer from '@/components/Spacer.vue';
import Loading from '@/components/Loading.vue';
import Card from '@/components/Post/Card.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'archive',
  mixins: [cf],
  components: {
    Spacer,
    Loading,
    Card,
  },
  data() {
    return {
      flag: {
        loading: true,
      },
      postType: null,
      posts: [],
      sortedPosts: {
        pc: { left: [], right: [] },
        sp: { left: [], right: [] },
      },
      contetnsLimit: {
        news: 10,
        educate: 10,
        work: 10,
      },
    };
  },
  computed: {
    ...mapState(['page', 'helper', 'view']),
  },
  mounted() {
    /** ダミーデータ取得 */
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'helper/putMaster') this.setPostType(this.$route);
    });
  },
  created() {
    this.setPostType(this.$route);
  },
  watch: {
    $route(to) {
      this.posts = [];
      this.sortedPosts = {
        pc: { left: [], right: [] },
        sp: { left: [], right: [] },
      };
      if (to.name === 'Home') return;
      this.setPostType(to);
    },
  },
  methods: {
    // this.$route.nameから現ページ特定
    setPostType(route) {
      if (!Object.keys(this.helper.master).length) return;
      const matchPostType = this.helper.master.postTypes.filter((d) => {
        const name = d.name;
        return route.name.includes(name);
      });
      this.postType = matchPostType[0];
      this.getPosts();
    },

    /**
     * postTypeに応じた投稿を取得
     */
    async getPosts() {
      if (this.postType.name === 'news') {
        this.getTimes();
      } else if (this.postType.name === 'educate') {
        this.getEducation();
      }
      // 取り急ぎダミー
      // 実際にはpostType.nameのデータを取得する
      const posts = cloneDeep(this.helper.master.posts[this.postType.name]);
      if (!posts) {
        // this.flag.loading = false;
        return;
      }
      // 画像の向きを取得する
      const inPiecesPosts = [];
      await Promise.all(
        posts.map(async (post, index) => {
          // 暫定処理
          if (!post.urls || !post.urls[0]) {
            post.urls = ['/img/logo/basic.png'];
            post.noimage = true;
          }
          const imageDirection = await cf.getImageDirection(post.urls[0]);
          post.direction = imageDirection;
          post.index = index;
          inPiecesPosts.push(post);
        }),
      );
      // direction取得時に順番狂うためソート
      const sortedPosts = await cf.ObjectValueSort(inPiecesPosts);
      sortedPosts.forEach((p) => { this.posts.push(p); });
      this.sortPostItems();
    },

    /**
     * 一覧表示用に投稿を並び替え
     * PCとSP用に別の配列を作成・出力を切り替える
     * SPはDESCでleftに全て格納
     */
    sortPostItems() {
      this.posts.forEach((p, i) => {
        const num = i + 1;
        this.sortedPosts.sp.left.push(p);
        if (num === 1) {
          this.sortedPosts.pc.left.push(p);
        } else if (num >= 2 && num <= 5) {
          this.sortedPosts.pc.right.push(p);
        } else if (num >= 6 && num <= 7) {
          this.sortedPosts.pc.left.push(p);
        } else if (num >= 8 && (num % 4 === 0 || num % 4 === 1)) {
          // 8~9,12~13,16~17...（4の倍数+1）
          this.sortedPosts.pc.right.push(p);
        } else {
          // 10~11,14~15,18~19...
          this.sortedPosts.pc.left.push(p);
        }
      });
      this.flag.loading = false;
    },

    /**
     * タイムスの記事を取得する
     */
    async getTimes() {
      this.flag.loading = true;
      await this.axios({
        method: 'GET',
        url: '/v1/news/get/times',
      })
        .then((response) => {
          // rss取得に成功したらxmlをパース
          xml2js.parseString(response.data.news.data, (error, result) => {
            if (error) {
              console.log(error);
            } else {
              // 各項目に取得したものを埋め込み
              result.rss.channel[0].item.forEach((elem) => {
                if (this.contetnsLimit.news <= 0) return true;
                this.posts.push({
                  title: elem.title[0],
                  urls: elem.enclosure[0].$.url,
                  summary: elem.description,
                  link: elem.link[0],
                });
                this.contetnsLimit.news -= 1;
              });
            }
          });
          this.sortPostItems();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /**
     * 教育に表示するコンテンツを取得
     */
    async getEducation() {
      this.flag.loading = true;
      await this.axios({
        method: 'GET',
        url: '/v1/category/get/education',
      })
        .then((response) => {
          // rss取得に成功したらxmlをパース
          xml2js.parseString(response.data.contents, (error, result) => {
            if (error) {
              console.log(error);
            } else {
              result.rss.channel[0].item.forEach((elem) => {
                let noimage = false;
                if (this.contetnsLimit.educate <= 0) return true;
                const searchTarget = elem['content:encoded'][0];
                const start = searchTarget.indexOf('src="');
                let imgUrl;
                if (start <= 0) { // 画像のない記事の場合
                  imgUrl = '/img/logo/basic.png';
                  noimage = true;
                } else { // 記事内に画像がある
                  const end = searchTarget.indexOf('" ', start + 5); // 'src='の文字列分の長さ
                  imgUrl = searchTarget.slice((start + 5), end);
                }

                this.posts.push({
                  title: elem.title[0],
                  urls: imgUrl,
                  summary: elem.description,
                  link: elem.link[0],
                  noimage,
                });

                this.contetnsLimit.educate -= 1;
              });
            }
          });
          this.sortPostItems();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.archive {
  position: relative;
}
.bg-accent {
  $start_height: 100px;
  $end_height: 150px;
  height: 290px;
  background-color: var(--green);
  position: absolute;
  top: calc(($start_height / 2) + 16px);
  left: 0;
  width: 100%;
  z-index: -1;
  &::before, &::after {
    position: absolute;
    width: 100vw;
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::before {
    background-image: url(/img/dammy/archive_start.svg);
    $height: calc(100vw * (226 / 1440));
    height: $height;
    top: calc($height * -1 * 1 / 2);
  }
  &::after {
    background-image: url(/img/dammy/archive_end.svg);
    $height: calc(100vw * (528 / 1440));
    height: $height;
    bottom: calc($height * -1 * 2 / 3);
  }
}

$types: 'article', 'news', 'event', 'shop';
@each $name in $types {
  .icon_#{$name} {
    &:before {
      background-image: url(/img/icons/icon_#{$name}.svg);
    }
  }
}

.comingsoon {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.columns {
  $mgn: 10%;
  display: flex;
  align-items: flex-start;
  width: 100%;
  .left,
  .right {
    width: percentage(600/1280);
    display: flex;
    flex-wrap: wrap;
    margin-top: -$mgn;
    > * {
      margin-top: $mgn;
    }
  }
  .left {
    > * {
      &:nth-child(odd):not(:first-child) {
        margin-left: auto;
      }
    }
  }
  .right {
    margin-left: auto;
    > * {
      &:nth-child(even) {
        margin-left: auto;
      }
    }
  }
}

.sp-posts {
  display: flex;
  flex-wrap: wrap;
  > * {
    &:nth-child(odd):not(:first-child) {
      margin-left: auto;
    }
  }
}

@include sm-view {
.sp-posts {
  *:nth-child(odd){
    &:not(:first-child){
      margin-left: 0;
    }
  }
  .item {
    &:nth-child(n + 3){
      margin-top: 28px;
    }
  }
  margin: 0 -1.5vw;
}
}

</style>
