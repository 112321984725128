<template>
  <footer :class="$style.footer">
    <small>R2 事業再構築 機 - 1</small>
    <p>Copyright &copy; Fun Kiryu All Right Reserved.</p>
  </footer>
</template>

<script>

export default {
  name: 'GlobalFooter',
  components: {
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.footer {
  padding: 100px 0 40px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  small {
    font-size: 10px;
  }
}
@include sm-view {
  .footer {
    font-size: 12px;
  }
}
</style>
