<template>
  <p :class="$style.message">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'ErrorMessage',
};
</script>

<style module lang="scss">
.message {
  margin: 15vh auto;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: var(--font-gray);
}
</style>
