<template>
  <span>
    <span :class="$style.title">
      <i :class="icon" class="icon"
        @:mouseover="showFlag = true"
        @:mouseleave="showFlag = false"></i>
      <div
        :class="$style.info_content"
        :style="{width: boxSize + 'px'}"
        v-if="showFlag">{{onHoverText}}</div>
    </span>
  </span>
</template>

<script>
export default {
  name: 'GuideIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    onHoverText: {
      type: String,
      required: true,
    },
    boxSize: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      showFlag: false,
    };
  },
  created() {
  },
};
</script>

<style lang="scss" module>
.title {
  position: relative;
}
.nomal {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.info_content {
  position: absolute;
    z-index: 6;
    top: 100%;
    left: 120%;
    transform: translate(0, -50%);
    width: 300px;
    font-size: .9em;
    font-weight: normal;
    background-color: white;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
</style>

<style lang="scss" scoped>
.nomal {
  font-weight: normal;
  display: inline;
  padding-right: 5px;
}
.bold {
  font-weight: bold;
  display: inline;
  padding-right: 5px;
}
.icon {
  cursor: pointer;
}
</style>
