<template>
  <div :class="$style.container" class="main_column">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MainColumn',
};
</script>

<style module lang="scss">
.container {
  width: 77%;
  padding-right: calc(var(--s) * 3);

  @include md-view {
    width: 100%;
    padding-right: 0;
  }
}
</style>
