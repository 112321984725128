<template>
  <div>
    <div :class="[$style.wrap, isCenter ? $style.center : '']">
      <div :class="$style.loading">
        <div :class="$style.inner">
          <svg :class="$style.icon" viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10"></circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isCenter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  &.center {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

.loading {
  width: 35px;
  height: 35px;
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform-origin: center center;
  animation: loader-spin 2000ms linear infinite;
}

.icon circle {
  stroke: #aaa;
  stroke-width: 3px;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-stroke 1500ms ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes loader-spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes loader-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
