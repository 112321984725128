<template>
  <div :class="[
    $style.card,
    $style[data.direction],
    isHome ? $style.minFix : '',
    isMain ? $style.isMain : '',
    isHorizontal ? $style.isHorizontal : '',
    ]">
    <router-link
      v-if="(data.slug && !data.link)"
      class="bg-flow"
      :to="`/${postType.name}/${data.slug}/`">
      <div class="image" :class="[$style.image, { noimage: data.noimage }]">
        <div
          class="image-body"
          :class="[
            $style['image-body'],
            $style[data.direction],
            isSquare ? $style.isSquare : '',
            data.noimage ? getRandomBgClass() : '',
          ]"
          :style="`background-image: url(${(typeof data.urls === 'object') ? data.urls[0] : data.urls});`"></div>
      </div>
      <spacer :y="1.5" />
      <p class="target" :class="$style.title"><span>{{ data.title || data.shopname }}</span></p>
      <spacer :y="1" />
      <p :class="$style.description" v-html="data.summary || data.description"></p>
    </router-link>

    <a
      v-else
      :href="data.link" target="_blank"
      class="bg-flow">
      <div class="image" :class="[$style.image, { noimage: data.noimage }]">
        <div
          class="image-body"
          :class="[
            $style['image-body'],
            $style[data.direction],
            isSquare ? $style.isSquare : '',
            data.noimage ? getRandomBgClass() : '',
          ]"
          :style="`background-image: url(${(typeof data.urls === 'object') ? data.urls[0] : data.urls});`"></div>
      </div>
      <spacer :y="1.5" />
      <p class="target" :class="$style.title"><span>{{ data.title || data.shopname }}</span></p>
      <spacer :y="1" />
      <p :class="$style.description" v-html="data.summary || data.description"></p>
    </a>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Card-post',
  mixins: [cf],
  components: {
    Spacer,
  },
  props: {
    isSquare: {
      type: Boolean,
      default: false,
    },
    isHome: {
      type: Boolean,
      default: false,
    },
    isMain: {
      type: Boolean,
      default: false,
    },
    postType: {
      type: Object,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['helper']),
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.card {
  width: percentage(260/600);
  max-width: 284px;
  &.portrait {
    + .square {
      margin-bottom: -5%;
    }
  }
  // 強制正方形
  &.isMain {
    width: 100%;
    max-width: 624px;
  }
  // トップサイズ固定
  &.minFix {
    width: 358px;
    min-width: 358px;
    max-width: none;
  }
  a {
    padding: 12px;
    display: block;
    transition: all .4s;
    &:hover {
      background-color: rgba(black, .05);
    }
  }
  .image-body {
    padding-top: 100%;
    background-size: contain;
    &.portrait {
      padding-top: percentage(378/260);
    }
    // isSquareは画像サイズに関わらず正方形
    &.isSquare {
      padding-top: 100%;
    }
  }
}
.title {
  font-size: 18px;
  line-height: 1.4;
  color: var(--font-title);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.description {
  font-size: 12px;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@include sm-view {
  .card {
    max-width: none;
    width: 50%;
    &.minFix {
      width: calc(100% / 2);
      min-width: calc(100% / 2);
    }
    a {
      padding: 1.5vw;
    }
  }
  .title {
    font-size: 14px;
  }
}
</style>
