<template>
  <div
    class="image"
    :class="{ noimage }">
    <div
      class="image-body"
      :class="[$style['image-body'], noimage ? getRandomBgClass() : '']"
      :style="`background-image: url(${url})`"/>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions';

export default {
  name: 'thumbnail',
  mixins: [cf],
  props: {
    url: {
      type: String,
      required: true,
    },
    noimage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.image-body {
  padding-top: calc(100% * (420 / 640));
  background-position: center;
  background-size: cover;
}
</style>
