<template>
  <div :class="$style.about">
    <div
      :class="[$style.introduction, scroll.flag.home.introduction ? $style.isShow : '']"
      ref="introduction">
      <div class="container" :class="$style['vertical-wrapper']">
        <div class="vertical-line">
          <h2 :class="$style.title">群馬県・桐生市</h2>
          <p :class="$style.text">歴史や文化が感性を刺激する。<br>
            豊かな自然が本能を呼び起こす。<br>
            グルービーな人と人のつながりから<br>
            今夜も生まれるインスピレーション。</p>
        </div>
      </div>
    </div>

    <spacer :y="20" />
    <div
      :class="[$style.snap, scroll.flag.home.snap ? $style.isShow : '']"
      ref="snap">
      <div class="container">
        <div :class="$style.columns">
          <div :class="$style.left">
            <div class="image" :class="$style.image">
              <h3 class="image-body" :class="$style.logo">FUN KIRYU</h3>
            </div>

            <spacer :y="10" :smY="7" />
            <div :class="$style.text">
              <p>繊維の街、ものづくりの街として<br>
                つねに新しい価値を生み出し続けてきた<br>
                群馬県、桐生市。</p>
              <p>かつての創造力は、風土として根付き、<br>
                街の個性を育み、他にない魅力つくっている。</p>
              <p>暮らし、仕事、学び、遊び…。</p>
              <p>「FUNKIRYU」は<br>
                桐生のでの暮らしや観光をより楽しむためのヒントを
                さまざまな切り口からお伝えするメディアです。</p>
            </div>
          </div>

          <div :class="$style.right">
            <div
              :class="$style.photos"
              v-if="photos.length">
              <div
                v-for="(row, i) in photos"
                :key="i"
                :class="$style.photo">
                <div class="image">
                  <div
                    class="image-body"
                    :class="$style['photo-body']"
                    :style="`background-image: url(${row.img});`"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Home-about',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      photos: [
        { img: '/img/dammy/snap/s1.jpg' },
        { img: '/img/dammy/snap/s2.jpg' },
        { img: '/img/dammy/snap/s3.jpg' },
        { img: '/img/dammy/snap/s4.jpg' },
        { img: '/img/dammy/snap/s5.jpg' },
        { img: '/img/dammy/snap/s6.jpg' },
        { img: '/img/dammy/snap/s7.jpg' },
        { img: '/img/dammy/snap/s8.jpg' },
        { img: '/img/dammy/snap/s9.jpg' },
        { img: '/img/dammy/snap/s10.jpg' },
      ],
    };
  },
  computed: {
    ...mapState(['page', 'helper', 'scroll']),
  },
  created() {
  },
  async mounted() {
    // DOMの更新完了を待つ
    await this.$nextTick();
    this.getAreaPos();
  },
  methods: {
    /** フェード処理のためのposition */
    getAreaPos() {
      const areas = ['introduction', 'snap'];
      areas.forEach((area) => {
        const data = this.$refs[area].getBoundingClientRect();
        const args = {
          type: 'positions',
          page: 'home',
          name: area,
          data,
        };
        this.$store.dispatch('scroll/setData', args, { root: true });
      });
    },
  },
};
</script>

<style lang="scss" module>
.about {
}
.introduction {
  padding: 175px 0 319px;
  background: center url(/img/home/about.svg) no-repeat;
  background-size: cover;
  position: relative;
  &:before,
  &:after {
    content: "";
    width: 100%;
    padding-top: percentage(140/1560);
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-size: 110%;
  }
  &:before {
    top: -2px;
    background-position: top center;
    background-image: url(/img/dammy/about_start.svg);
  }
  &:after {
    bottom: -2px;
    background-position: bottom center;
    background-image: url(/img/dammy/about_end.svg);
  }
  font-family: $minchoFont;
  font-style: normal;
  font-weight: 300;
  color: white;
  line-height: 1.5;
  .vertical-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 32px;
    position: absolute;
    top: 0;
    right: 132px;
    font-weight: 300;
  }
  .text {
    font-weight: 300;
    font-size: 20px;
    line-height: 3;
    letter-spacing: 8px;
    min-height: 26em;
    margin-top: 175px;
  }
}

.columns {
  display: flex;
  .left {
    width: percentage(430/1272);
    .logo {
      padding-top: percentage(139/384);
      background-image: url(/img/logo/text.svg);
    }
    .text {
      p {
        font-size: 16px;
        line-height: 2;
        &:not(:first-child) {
          margin-top: 1.8em;
        }
      }
    }
  }
  .right {
    width: percentage(735/1272);
    margin-left: auto;
    .photos {
      display: flex;
      flex-wrap: wrap;
    }
    .photo {
      width: percentage(344/736);
      .photo-body {
        padding-top: percentage(480/344);
        background-size: cover;
      }
      &:nth-child(even) {
        margin-left: auto;
      }
      &:not(:first-child) {
        margin-top: 10%;
        &:nth-child(odd) {
          margin-top: 0;
        }
      }
    }
  }
}


/** fade animations */
.introduction {
  opacity: 0;
  &.isShow {
    animation: fadeIn 3s forwards;
  }
}
.snap {
  .left {
    opacity: 0;
    transition: all 2s;
    transform: translate3d(-40px, 40px, 0);
  }
  .right {
    .photo {
      opacity: 0;
    }
  }
  &.isShow {
    .left {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    .right {
      .photo {
        animation: photoFade 2.5s forwards;
        @for $i from 0 through 20 {
          &:nth-child(#{$i}) {
            animation-delay: .5s * $i;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes photoFade {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@include sm-view {
  .introduction {
    padding: 5em 0 4em;
    .title {
      font-size: 28px;
      right: 0;
    }
    .text {
      font-size: 16px;
      margin-top: 2em;
      line-height: 44px;
    }
  }

  .columns {
    display: block;

    // 画面幅からsticky-containerの高さを計算
    $beforelogomgn: 160px;
    $logoW: calc(100vw - 40px * 2);
    $logoH: calc($logoW * 221 / 612);
    $logo2text: 56px;
    $textH: 360px;
    $text2imgs: 120px;
    $imgsW: 100vw;
    $imgsH: calc($imgsW * 1466 / 390);

    $total: calc(($logoH + $logo2text + $textH + $text2imgs) * 2 + $imgsH + $beforelogomgn);

    height: $total;

    .left {
      width: 100%;
      position: sticky;
      top: 85px;
      left: 0;
      z-index: 2;
      .image {
        max-width: 80%;
        margin: 0 auto;
      }
      .logo {
        width: 100%;
        padding-top: percentage(194/532);
        background-image: url(/img/logo/logo_text.png);
      }
      .text {
        p {
          font-size: 14px;
          &:not(:first-child) {
            margin-top: 1.2em;
          }
        }
      }
    }
    .right {
      margin-left: -6vw;
      margin-top: 120px;
      width: 100vw;
      position: relative;
    }
  }
}
</style>
