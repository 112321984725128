const actions = {
  insertImage({ commit }, args) {
    commit('setImage', args);
  },
};

const getters = {
  imageData(state) {
    return state.imageData;
  },
};

const mutations = {
  setImage(state, args) {
    state.imageData = args;
  },

};

const state = {
  imageData: null,
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
