<template>
  <div
    class="editor"
    :class="$style.content"
    v-html="content" />
</template>

<script>
export default {
  name: 'content',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.content {
  font-size: 16px;
}
</style>
