<template>
  <div>
    <p class="fs-3 fw-bold mb-4"><i class="fa-solid fa-user me-2" />プロフィール設定</p>

    <div class="row" v-if="helper.master.labels">
      <div class="col-10">
        <form>
          <!-- プロフィール画像 -->
          <dl>
            <dt class="form-label">
              プロフィール画像
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'著者欄のプロフィール画像として設定されます。'" />
            </dt>
            <dd>
              <div class="mt-2 d-flex align-items-center">
                <label
                  for="profile"
                  v-on:click="openImageLibrary('profile', false)">
                  <div
                    v-if="profileimage.s3"
                    class="preview_L preview"
                    :style="`background-image: url(${profileimage.s3})`"/>
                  <div
                    v-else
                    class="preview_L preview input_btn"/>
                </label>
                <div class="ms-4">
                  <div
                    class="btn btn-secondary"
                    v-if="profileimage.s3"
                    v-on:click="deleteFile('profile')">削除する</div>
                </div>
              </div>
            </dd>
          </dl>

          <!-- 表示名 -->
          <dl>
            <dt class="form-label">
              表示名
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'アーティクル等の著者欄の表示名です。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="displayname">
            </dd>
          </dl>

          <!-- 概要 -->
          <dl>
            <dt class="form-label">概要
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'プロフィールの概要です。経歴などを記入してください。'" />
            </dt>
            <dd>
              <Editor
                name="description"
                id="description"
                v-model="description"
                placeholder="プロフィール概要を入力してください。"
                v-bind:init="editorConf"
              />
            </dd>
          </dl>

          <!-- Facebook -->
          <dl>
            <dt class="form-label">
              Facebook
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'Facebookのユーザーネームを入力してください。URL形式ではありません。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.facebook">
            </dd>
          </dl>
          <!-- Twitter -->
          <dl>
            <dt class="form-label">
              Twitter
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'Twitter'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.twitter">
            </dd>
          </dl>
          <!-- Instagram -->
          <dl>
            <dt class="form-label">
              Instagram
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'Instagramのユーザーネームを入力してください。URL形式ではありません。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.instagram">
            </dd>
          </dl>
          <!-- Web -->
          <dl>
            <dt class="form-label">
              Webサイト
              <guide-icon
                :icon="'fa-regular fa-circle-info'"
                :on-hover-text="'WebサイトのURLを設定してください。'" />
            </dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.website">
            </dd>
          </dl>
          <!-- 住所 -->
          <dl>
            <dt class="form-label">郵便番号</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.zip">
            </dd>
          </dl>
          <dl>
            <dt class="form-label">都道府県</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.pref">
            </dd>
          </dl>
          <dl>
            <dt class="form-label">市区</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.city">
            </dd>
          </dl>
          <dl>
            <dt class="form-label">町村</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.town">
            </dd>
          </dl>
          <dl>
            <dt class="form-label">番地</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.address">
            </dd>
          </dl>
          <dl>
            <dt class="form-label">建物名</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.building">
            </dd>
          </dl>
          <dl>
            <dt class="form-label">電話番号</dt>
            <dd>
              <input
                class="form-control form-control-md"
                type="text"
                v-model="addressData.tel">
            </dd>
          </dl>
        </form>

        <ul class="mt-3">
          <li>
            <div
              v-on:click="submit"
              class="btn btn-primary btn-sm">保存する</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import cf from '@/mixins/commonFunctions.js';
import GuideIcon from '@/components/GuideIcon.vue';
// import Spacer from '@/components/Spacer.vue';

export default {
  name: 'Admin-article-create',
  mixins: [cf],
  components: {
    Editor,
    GuideIcon,
    // Spacer,
  },
  data() {
    return {
      flag: {
      },
      displayname: null,
      description: null,
      profileimage: {
        media_id: null,
        s3: null,
      },
      addressId: null,
      addressData: {
        facebook: null,
        twitter: null,
        instagram: null,
        website: null,
        zip: null,
        pref: null,
        city: null,
        town: null,
        address: null,
        building: null,
        tel: null,
      },
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        height: 500,
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        toolbar: 'blocks | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image | undo redo | code | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        plugins: 'lists link code table textcolor',
        block_formats: 'Paragraph=p; 見出し１=h1; 見出し２=h2; 見出し３=h3; 見出し４=h4; 見出し５=h5; 見出し６=h6',
      },
    };
  },
  created() {
    if (this.user.email) {
      this.setData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.setData();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    imageData() {
      return this.$store.getters['modal/imageSelect/imageData'];
    },
  },
  watch: {
    imageData(data) {
      this.insertImage(data);
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 画像を挿入 */
    insertImage(data) {
      if (data.name === 'profile') {
        this.profileimage.s3 = data.images[0].url;
        this.profileimage.media_id = data.images[0].id;
      }
    },

    setData() {
      console.log(this.user);
      this.displayname = this.user.username;
      this.description = this.user.description;
      if (this.user.address && this.user.address.length) {
        const address = this.user.address[0];
        this.addressId = address.id;
        const keys = Object.keys(this.addressData);
        keys.forEach((key) => {
          this.addressData[key] = address[key];
        });
      }
      if (this.user.images && this.user.images.length) {
        const image = this.user.images[0];
        this.profileimage.media_id = image.id;
        this.profileimage.s3 = image.url;
      }
    },

    async submit() {
      if (!this.displayname || this.displayname === '') return alert('表示名を入力してください。');
      this.showLoading();

      const userData = {
        id: this.user.id,
        username: this.displayname,
        description: this.description,
      };
      const imageData = {
        foreign_type: 1,
        foreign_id: this.user.id,
        media_id: this.profileimage.media_id,
        is_primary: 1,
      };
      const addressData = {
        address: this.addressData,
        foreign_id: this.user.id,
        foreign_type: 1,
      };
      try {
        await this.submitUser(userData);
        await this.submitProfileImage(imageData);
        await this.submitAddress(addressData);
        alert('ユーザー情報を更新しました。');
      } catch (error) {
        console.log(error);
      }

      this.$store.dispatch('user/update');
      this.hideLoading();
    },

    async submitUser(data) {
      await this.axios({
        method: 'POST',
        url: '/v1/user/update',
        data,
      })
        .then(() => {
        })
        .catch((error) => {
          alert('ユーザー情報更新中にエラーが発生しました。\n再度お試しください。');
          if (error.message) console.log(error.message);
          console.log(error);
        });
    },

    async submitProfileImage(data) {
      await this.axios({
        method: 'POST',
        url: '/v1/mediaRelation/create',
        data,
      })
        .then(() => {
        })
        .catch((error) => {
          alert('プロフィール画像更新中にエラーが発生しました。\n再度お試しください。');
          if (error.message) console.log(error.message);
          console.log(error);
        });
    },

    async submitAddress(data) {
      if (this.addressId) data.address.id = this.addressId;

      const type = this.addressId ? 'update' : 'create';

      await this.axios({
        method: 'POST',
        url: `/v1/address/${type}`,
        data: type === 'update' ? data.address : data,
      })
        .then(() => {
        })
        .catch((error) => {
          alert('SNS情報設定中にエラーが発生しました。\n再度お試しください。');
          if (error.message) console.log(error.message);
          console.log(error);
        });
    },

    /** サムネイル・ギャラリーを削除 */
    deleteFile(name) {
      if (name === 'profile') {
        this.profileimage.s3 = null;
        this.profileimage.media_id = null;
      }
    },

    /** イメージライブラリーを開く */
    openImageLibrary(name, multiple) {
      const data = {
        name,
        multiple,
      };
      const args = {
        modalName: 'imageLibrary',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>
