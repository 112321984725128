<template>
  <div :class="$style.wrap" class="md-flex">
    <div :class="$style.user_wrap">
      <div :class="$style.user"
        :style="`background-image: url(${this.userIcon})`" />
      <p
        class="mt-2"
        :class="$style.username">{{ user.username || 'ユーザーネーム未設定' }}</p>
      <p
        class="mt-1"
        :class="$style.role"
        v-if="helper.master && helper.master.labels && user.role">{{ helper.master.labels.roles[user.role.role] }}</p>
    </div>

    <ul
      :class="$style.links">
      <li
        v-for="item in items"
        :key="item"
        v-show="user.role && item.acceptRoles.includes(user.role.role)">
        <router-link
          :class="[
            $style.link,
            item.name === isActive ? $style.active : '',
          ]"
          :to="{
            path: item.path,
            query: item.query ? item.query : null,
          }">
          <i :class="item.icon" class="me-1" />
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isActive: 'top',
      items: [
        {
          name: 'article',
          path: '/admin/article/',
          query: {
            page: 1,
          },
          label: '記事',
          icon: 'fa-solid fa-pen-nib',
          acceptRoles: [1, 11, 21],
        },
        {
          name: 'event',
          path: '/admin/event/',
          query: {
            page: 1,
          },
          label: 'イベント',
          icon: 'fa-solid fa-party-horn',
          acceptRoles: [1, 11, 21],
        },
        {
          name: 'shop',
          path: '/admin/shop/',
          label: 'ショップ',
          icon: 'fa-solid fa-shop',
          acceptRoles: [1, 11, 21],
        },
        {
          name: 'profile',
          path: '/admin/profile/',
          label: 'プロフィール',
          icon: 'fa-solid fa-user',
          acceptRoles: [1, 11, 21],
        },
      ],
    };
  },
  created() {
    this.setIsActive();
  },
  computed: {
    ...mapState(['user', 'helper']),
    userIcon() {
      let url = '/img/noimage.jpg';
      if (this.user.images
        && this.user.images.length
        && this.user.images[0].url) url = this.user.images[0].url;
      return url;
    },
  },
  watch: {
    $route() {
      this.setIsActive();
    },
  },
  methods: {
    setIsActive() {
      this.isActive = 'top';
      this.items.forEach((item) => {
        if (this.$route.path.includes(item.path) && this.$route.path !== '/') {
          this.isActive = item.name;
        }
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  border-right: 1px solid var(--light-gray);
  padding-right: 0;
  box-shadow: 0px 0 4px 1px rgba(#2d2d2d, .1);
}
.user {
  &_wrap {
    padding: 10%;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-bottom: 1px solid var(--light-gray);
  }
  width: 50%;
  padding-top: 50%;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}
.role {
  font-size: 12px;
  padding: 2px 8px;
  background-color: var(--light-gray);
  border-radius: 4px;
}
.link {
  padding: 8px 12px;
  display: block;
  color: var(--black);
  border-bottom: 1px solid var(--light-gray);
  position: relative;
  &.active {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: var(--font-black);
    }
  }
}

@include md-view {
  .wrap {
    display: block;
    width: 100%;
    border-right: none;
  }
  .user_wrap {
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }
  .user {
    $size: 2em;
    width: $size;
    height: $size;
  }
  .username {
    display: none;
  }
  .role {
    display: none;
  }
  .links {
    display: flex;
    li {
      flex: 1;
      border-left: 1px solid #ddd;
    }
  }
  .link {
    font-size: .8em;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-top: .1em;
    }
    &.active {
      font-weight: bold;
      &:after {
        content: none;
      }
    }
  }
}

@include sm-view {
  .links {
    li {
      flex: auto;
    }
  }
}
</style>
