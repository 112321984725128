<template>
  <div class="imageLibrary">
    <!-- top(fix) -->
    <div class="fix fix_top">
      <!-- title -->
      <p class="modal-title fw-bold mb-3">
        <i class="fa-solid fa-image me-2" />
        メディアライブラリー
      </p>
      <!-- tab -->
      <ul class="library-tab">
        <li
          v-for="tab in tabs"
          :key="tab"
          :class="{ active : tab.name === isActive }"
          v-on:click="changeTab(tab.name)">{{ tab.label }}</li>
      </ul>
    </div>

    <!-- content -->
    <div class="content">
      <!-- input -->
      <div
        class="tab-content"
        v-if="isActive === 'input'">
        <div class="tab-content_inner tab-border">
          <div class="library-droparea"
            v-on:dragover="dragover"
            v-on:drop="receiveFiles"
            v-show="!beforeUploadFiles.length">
            <div class="library-droparea_text">
              <p class="guide">アップロードしたいファイルをドロップ<br>または</p>
              <input
                type="file"
                id="media"
                style="display: none"
                :multiple="data.multiple"
                accept=".jpg, .jpeg, .png, .gif"
                v-on:change="receiveFiles">
              <label
                for="media"
                class="input_label">ファイルを選択</label>
            </div>
          </div>
          <ul
            class="library-contents"
            v-if="beforeUploadFiles.length">
            <li v-for="(file, i) in beforeUploadFiles"
              :key="file">
              <div
                class="waiting"
                :style="`background-image: url(${file.base64})`">
                <div class="delete_wrap">
                  <div
                    class="delete"
                    v-on:click="deleteFile(i)"><span></span><span></span></div>
                </div>
              </div>
            </li>
            <li>
              <label
                for="media"
                class="waiting input"><i class="fa-solid fa-plus"/></label>
            </li>
          </ul>
        </div>
      </div>

      <!-- list -->
      <div
        v-if="isActive === 'list'"
        class="tab-content">
        <div class="row tab-content_inner list">
          <div class="col-9 tab-border scroll_area">
            <ul
              class="library-contents scroll">
              <li v-for="image in images"
                :key="image.id"
                :class="{ selected : selectedImageIds.includes(image.id) }">
                <div
                  v-on:click="selectImage(image)"
                  class="waiting"
                  :style="`background-image: url(${image.url})`">
                  <div class="icons">
                    <div
                      class="icon"
                      v-on:click="editCaption(image, $event)">
                      <i class="fa-regular fa-circle-info"></i>
                    </div>
                    <div
                      class="icon"
                      v-on:click="deleteImage(image, $event)">
                      <i class="fa-regular fa-trash-can"></i>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-3">
            <div class="card ms-3" v-if="isActive === 'list'">
              <p class="card-header fw-bold">検索</p>
              <div class="card-body">
                <div>
                  <p class="form-label">登録時期</p>
                  <div>
                    <date-small-picker
                      :name="'startCreatedAt'"
                      v-on:sendDate="receiveDate" />
                  </div>
                  <div class="wave"><i class="fa-light fa-wave-sine"></i></div>
                  <date-small-picker
                    :name="'endCreatedAt'"
                    v-on:sendDate="receiveDate" />
                </div>
                <div class="mt-2">
                  <p class="form-label">フリーワード</p>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="keyword"
                    v-on:change="updateValue">
                </div>
                <div class="mt-2">
                  <p class="form-label">ファイル登録者</p>
                  <select
                    name="registUser"
                    class="form-select form-select-sm"
                    v-model="registUserId"
                    v-on:change="updateValue">
                    <option :value="0">未選択</option>
                    <option
                      v-for="user of uploaders"
                      :key="user.id"
                      :value="user.id">{{ user.last_name || user.first_name ? user.last_name + user.first_name : '名前未設定' }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- bottom(fix) -->
    <div class="fix fix_bottom">
      <div class="d-grid">
        <button
          v-if="isActive === 'input'"
          class="btn btn-primary"
          :disabled="!(beforeUploadFiles.length &&  isActive === 'input')"
          v-on:click="resizeFile">アップロードする</button>
      </div>
      <div class="d-grid">
        <button
          v-if="isActive === 'list'"
          class="btn btn-primary"
          :disabled="!(selectedImages.length && isActive === 'list')"
          v-on:click="insertImage">選択した画像を挿入する</button>
      </div>
    </div>
    <div>
    </div>
    <div
      v-if="flag.openCaptionModal"
      :class="$style.captionModal_wrap"
      v-on:click="hideCaptionModal">
      <div
        v-on:click="$event.stopPropagation()"
        :class="$style.captionModal">
        <p class="mb-3 fw-bold">キャプション編集</p>
        <input
          class="form-control"
          type="text"
          v-model="caption">
        <div :class="$style.btn_wrap" class="mt-4">
          <div class="btn btn-primary btn-sm" @click="registCaption">保存</div>
          <div
            class="btn btn-outline-secondary btn-sm ms-2"
            v-on:click="hideCaptionModal">戻る</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DateSmallPicker from '@/components/DateSmallPicker.vue';

export default {
  name: 'image-library',
  props: ['data'],
  components: {
    DateSmallPicker,
  },
  data() {
    return {
      isActive: 'input',
      page: 1,
      limit: 50,
      images: [],
      beforeUploadFiles: [],
      selectedImages: [],
      selectedImageIds: [],
      keyword: null,
      registUserId: 0,
      uploaders: [],
      startCreatedAt: null,
      endCreatedAt: null,
      flag: {
        uploading: false,
        isAbleToNextPage: false,
        firstSort: true,
        openCaptionModal: false,
      },
      tabs: [
        {
          label: 'アップロード',
          name: 'input',
        },
        {
          label: 'ファイル一覧',
          name: 'list',
        },
      ],
      caption: null,
      selectedCaptionImage: {},
    };
  },
  created() {
    if (this.user.email) {
      this.getImage();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getImage();
        }
      });
    }
    this.getUsers();
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
  watch: {
    // 検索条件が変わったことを検知
    keyword() {
      this.page = 1;
    },
    registUserId() {
      this.page = 1;
    },
    startCreatedAt() {
      this.page = 1;
    },
    endCreatedAt() {
      this.page = 1;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrollArea, { capture: true });
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkScrollArea, {
      capture: true,
    });
  },
  methods: {
    /** 登録日の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      this.getImage();
    },

    /** ページネーションのためのスクロール値を取得 */
    checkScrollArea() {
      if (this.isActive === 'list') {
        const scrollAreaElement = document.querySelector('.scroll_area');
        const scrollAreaScrollTop = scrollAreaElement.scrollTop;
        const scrollAreaScrollHeight = scrollAreaElement.scrollHeight;
        const scrollAreaClientHeight = scrollAreaElement.clientHeight;
        // DEBUG用(一通り問題ないとなるまでは保持してほしいです)
        // console.log(`SATop: ${scrollAreaScrollTop}`);
        // console.log(`SAScrollHeight: ${scrollAreaScrollHeight}`);
        // console.log(`SAClientHeight: ${scrollAreaClientHeight}`);
        if ((scrollAreaScrollHeight - scrollAreaScrollTop <= scrollAreaClientHeight + 10) && this.flag.isAbleToNextPage) {
          this.flag.isAbleToNextPage = false; // 初期化
          this.page += 1;
          this.getImage();
        }
      }
    },

    /** アップロードと選択のタブの切替 */
    changeTab(name) {
      this.isActive = name;
    },

    /** モーダルを閉じる */
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },

    /** modal閉じるまたは元のmodalに移動 */
    closeImageLibrary(insertData) {
      if (this.data.modalName) {
        const data = { ...this.data.preModalData, ...insertData };
        data.from = 'imageLibrary';
        const args = {
          modalName: this.data.modalName,
          data,
        };
        this.$store.dispatch('modal/contents/showModal', args, { root: true });
      } else {
        this.hideModal();
      }
    },

    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 検索条件変更時に初期化 */
    checkSort() {
      if (!(this.keyword || this.registUserId || this.startCreatedAt || this.endCreatedAt)) {
        this.flag.firstSort = true;
        return false;
      }
      return true;
    },

    /** 検索条件変更 */
    updateValue() {
      const sorted = this.checkSort();
      this.getImage(sorted);
    },

    /** 既にアップロードされている画像の選択 API */
    getImage(isSort) {
      if (isSort && this.flag.firstSort) {
        this.page = 1;
        this.flag.firstSort = false;
      }

      this.axios({
        method: 'GET',
        url: '/v1/media/get/list',
        params: {
          types: [1],
          limit: this.limit,
          page: this.page,
          keyword: this.keyword ? this.keyword : null,
          registUserId: this.registUserId,
          betweenCreatedAt: [this.startCreatedAt, this.endCreatedAt],
        },
      })
        .then(async (response) => {
          if (this.page === 1) this.images = [];
          const res = response.data;
          if (res.list && res.list.data.length && res.list.total !== this.images.length) {
            await this.images.push(...res.list.data);

            this.$nextTick(() => {
              if (res.list.page < res.list.lastPage) {
                this.flag.isAbleToNextPage = true;
              }
            });
          } else {
            this.images = [];
            this.page = 1;
          }
        })
        .catch((error) => {
          alert('画像ライブラリの初期化に失敗しました。\n再度開き直してください。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** 対象roleのユーザーを取得 API */
    getUsers() {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params: {
          role: [1],
        },
      })
        .then((response) => {
          const res = response.data;
          this.uploaders = res.list.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 画像の選択 */
    selectImage(image) {
      if (this.data.multiple) {
        if (this.selectedImageIds.includes(image.id)) {
          const idx = this.selectedImageIds.indexOf(image.id);
          this.selectedImageIds.splice(idx, 1);
          this.selectedImages.splice(idx, 1);
        } else {
          this.selectedImages.push(image);
          this.selectedImageIds.push(image.id);
        }
      } else {
        this.selectedImageIds = [image.id];
        this.selectedImages = [image];
      }
    },

    /** アップロード前画像 削除 */
    deleteFile(i) {
      this.beforeUploadFiles.splice(i, 1);
    },

    /** 画像を記事に挿入 */
    insertImage() {
      const data = {
        name: this.data.name,
        images: this.selectedImages,
      };
      this.$store.dispatch('modal/imageSelect/insertImage', data, { root: true });
      this.closeImageLibrary(data);
    },

    /** ドラッグした場合にデフォルト動作をなくす */
    dragover(e) {
      e.preventDefault();
    },

    /** 受け取りファイルを処理 */
    receiveFiles(e) {
      e.preventDefault();

      const inputFiles = e.target.files ? e.target.files : e.dataTransfer.files;

      if (inputFiles.length !== 0) {
        Object.keys(inputFiles).forEach((index) => {
          if (inputFiles[index].type.includes('image')) this.formatImage(inputFiles[index]);
        });
      }
    },

    /** base64に変換 */
    formatImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Original = reader.result;
        // file保存・置換用
        this.beforeUploadFiles.push({
          file,
          base64: base64Original,
        });
      };
      reader.readAsDataURL(file);
    },

    /** 画像をリサイズ */
    async resizeFile() {
      await Promise.all(
        this.beforeUploadFiles.map(async (file) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const image = new Image();
          image.src = file.base64;

          // サイズ指定
          const max = 2000;
          // 元のサイズを取得
          const H = image.naturalHeight;
          const W = image.naturalWidth;

          // 縦横いずれかがmaxより大きい場合はリサイズする
          if (max < H || max < W) {
            // 長辺がmaxになるようにするので縮小割合を算出
            const baseSideLength = H > W ? H : W;
            const ratio = max / baseSideLength;
            // リサイズ後の大きさにcanvasを指定
            const resizeH = ratio * H;
            const resizeW = ratio * W;
            canvas.height = resizeH;
            canvas.width = resizeW;

            const imagePromise = new Promise((resolve) => {
              image.onload = () => {
                resolve(image);
              };
            });

            await imagePromise;

            // キャンバスに描画
            ctx.drawImage(image, 0, 0, resizeW, resizeH);
            // fileに格納
            const resizedDataUrl = canvas.toDataURL('image/jpeg');
            const filename = file.file.name;
            const filenameArray = filename.split('.');
            const name = filenameArray[0];

            // DataURLだとformDataで送れないのでFileに変換
            file.resized = await this.cvtDataURL2File(resizedDataUrl, `${name}-light.jpeg`);
          }

          // rss画像を生成
          file.rss = await this.generateRSSImage(file);
        }),
      )
        .then(() => {
          this.uploadFiles();
        });
    },

    /** RSS画像を生成 */
    async generateRSSImage(file) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = file.base64;

      // サイズ指定
      const max = 700;
      // 元のサイズを取得
      const H = image.naturalHeight;
      const W = image.naturalWidth;

      // 長辺がmaxになるようにするので縮小割合を算出
      const baseSideLength = H > W ? H : W;
      const ratio = max / baseSideLength;
      // リサイズ後の大きさにcanvasを指定
      const resizeH = ratio * H;
      const resizeW = ratio * W;
      canvas.height = resizeH;
      canvas.width = resizeW;

      const imagePromise = new Promise((resolve) => {
        image.onload = () => {
          resolve(image);
        };
      });

      await imagePromise;

      // キャンバスに描画
      ctx.drawImage(image, 0, 0, resizeW, resizeH);
      // fileに格納
      const resizedDataUrl = canvas.toDataURL('image/jpeg');
      const filename = file.file.name;
      const filenameArray = filename.split('.');
      const name = filenameArray[0];

      // DataURLだとformDataで送れないのでFileに変換
      const rssFile = await this.cvtDataURL2File(resizedDataUrl, `${name}-rss.jpeg`);
      return rssFile;
    },

    /** DataURLからFileを生成 */
    async cvtDataURL2File(dataURL, filename) {
      const blob = await (await fetch(dataURL)).blob();
      return new File([blob], filename, { type: 'image/jpeg' });
    },

    /** 画像をS3にアップロード API */
    async uploadFiles() {
      this.flag.uploading = true;
      this.showLoading();

      let failedCount = 0;

      const originalError = [];
      const resizedError = [];
      const rssError = [];
      await Promise.all(
        this.beforeUploadFiles.map(async (row) => {
          const form = new FormData();
          const filename = row.file.name;
          const filenameArray = filename.split('.');
          const name = filenameArray[0];
          const ext = filenameArray[1];
          const jpgFlag = ['jpg', 'jpeg'].includes(ext.toLowerCase());

          form.append('file', row.file);
          if (row.resized && jpgFlag) {
            form.append('resized', row.resized, `${name}.${ext}`);
          } else if (row.resized) {
            form.append('resized', row.resized);
          }
          if (row.rss && jpgFlag) {
            form.append('rss', row.rss, `${name}.${ext}`);
          } else if (row.rss) {
            form.append('rss', row.rss);
          }


          await this.axios({
            method: 'POST',
            url: '/v1/media/upload',
            data: form,
            params: {
              environment: this.helper.env.name,
              flag: 1,
              user_id: this.user.id,
              type: 1,
              alt: '',
            },
          })
            .then((response) => {
              const res = response.data.resultData;
              if (res.original.uploaded && res.original.uploaded.status !== 200) {
                originalError.push(res.filename);
                failedCount += 1;
              }
              if (res.resized.resizedUploaded && res.resized.resizedUploaded.status !== 200) {
                resizedError.push(res.filename);
                failedCount += 1;
              }
              if (res.rss.rssUploaded && res.rss.rssUploaded.status !== 200) {
                rssError.push(res.filename);
                failedCount += 1;
              }
            })
            .catch((error) => {
              if (error.response) console.log(error.response.data);
              else console.log(error);
              failedCount += 1;
            })
            .finally(() => {
            });
        }),
      )
        .then(async () => {
          // 複数画像アップロード時は1枚毎にrequestされるので、frontでアップロード終了を検知してtmp削除
          await this.axios({
            method: 'POST',
            url: '/v1/helper/delete/tmpDirectory',
            params: {
              path: `${this.helper.env.name}/ImageLibrary/User/${this.user.id}/imagemin`,
            },
          })
            .then(() => {
            })
            .catch((error) => {
              if (error.response) console.log(error.response.data);
              else console.log(error);
            });
        });
      if (originalError.length) {
        let images = '';
        originalError.forEach((err) => {
          images = `${images}${err}\n`;
        });
        alert(`以下の画像について、オリジナル画像のアップロードに失敗しました。\n${images}`);
      }
      if (resizedError.length) {
        let images = '';
        resizedError.forEach((err) => {
          images = `${images}${err}\n`;
        });
        alert(`以下の画像について、リサイズ画像のアップロードに失敗しました。\n${images}`);
      }
      if (rssError.length) {
        let images = '';
        rssError.forEach((err) => {
          images = `${images}${err}\n`;
        });
        alert(`以下の画像について、RSS用画像のアップロードに失敗しました。\n${images}`);
      }

      this.beforeUploadFiles = [];
      this.getImage();
      this.flag.uploading = false;
      this.hideLoading();
      if (failedCount === 0) {
        alert('画像をアップロードしました。');
      }
      this.isActive = 'list';
    },

    /** 画像を削除 API */
    deleteImage(image, e) {
      e.stopPropagation();
      if (!confirm('画像を削除します。よろしいですか？')) return;

      const data = {
        id: image.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/media/delete',
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.updated) {
            alert('画像を削除しました。');
          }
          this.page = 1;
          this.images = [];
          this.getImage();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** キャプションの編集モーダルを開く */
    editCaption(image, e) {
      e.stopPropagation();
      this.flag.openCaptionModal = true;
      this.selectedCaptionImage = image;
      this.caption = image.caption;
      if (!this.selectedCaptionImage.id) {
        alert('画像の選択中にエラーが発生しました。\n再度お試しください。');
      }
    },

    /** キャプション登録モーダルを閉じる */
    hideCaptionModal() {
      this.flag.openCaptionModal = false;
    },

    /** キャプション登録 API */
    registCaption() {
      const data = {
        id: this.selectedCaptionImage.id,
        caption: this.caption,
      };
      this.axios({
        method: 'POST',
        url: '/v1/media/update',
        data,
      })
        .then(() => {
          alert('キャプションを登録しました。');
          this.getImage();
          this.hideCaptionModal();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.captionModal {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 40px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 700px;


  &_wrap {
    background-color: rgba(black, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    padding: 20px;
  }
}

.btn_wrap {
  display: flex;
  justify-content: center;
}
</style>
