<template>
  <li :class="$style.wrap">
    <router-link
      :to="`/${postType}/${content.slug}`"
      :class="$style.link"
      class="bg-flow">
      <div
        class="image"
        :class="[$style.image, { noimage: content.noimage }]">
        <div
          class="image-body"
          :class="[$style.thumb, content.noimage ? getRandomBgClass() : '']"
          :style="`background-image: url(${content.urls[0]})`" />
      </div>
      <Spacer :x="2.5"/>
      <div :class="$style.detail">
        <p :class="$style.title" class="target"><span>{{ content.title || content.shopname }}</span></p>
        <p :class="$style.description" v-html="content.summary || content.description"></p>
      </div>
    </router-link>
  </li>
</template>

<script>
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'simple-list-item',
  mixins: [cf],
  components: {
    Spacer,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      postType: null,
    };
  },
  created() {
    this.postType = this.$route.path.split('/')[1];
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.wrap {
  a {
    padding: 10px;
    color: var(--font-title);
    &:hover {
      background-color: rgba(black, .05);
    }
  }
}
.link {
  display: flex;
}
.image {
  width: 120px;
}
.thumb {
  padding-top: 100%;
  background-size: cover;
  background-position: center;
}
.detail {
  flex: 1;
}
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.description {
  font-size: 12px;
  line-height: 24px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
@include sm-view {
  .wrap {
    a {
      padding: 12px 0;
    }
  }
  .thumb {
    width: 90px;
    height: 90px;
  }
  .detail {
    padding: 0 0;
  }
  .title {
    font-size: 14px;
    line-height: 18px;
  }
  .description {
    font-size: 10px;
    line-height: 16px;
  }
}
</style>
