<template>
  <div>
    <div
      :class="$style.home"
      v-if="Object.keys(helper.master).length">
      <first-view :postTypes="helper.master.postTypes" />

      <spacer :y="15" :smY="10" />

      <posts
        v-for="row in helper.master.postTypes"
        :key="row.name"
        :postType="row"
        v-on:loaded="loadState" />

      <spacer :y="15"/>
      <about v-if="flag.showAbout" />
    </div>
    <Loading v-else :isCenter="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  FirstView,
  Posts,
  About,
} from '@/views/Home/Area';
import Spacer from '@/components/Spacer.vue';
import Loading from '@/components/Loading.vue';

export default {
  name: 'Home',
  components: {
    FirstView,
    Posts,
    About,
    Spacer,
    Loading,
  },
  data() {
    return {
      flag: {
        showAbout: false,
      },
      postLoaded: {},
    };
  },
  computed: {
    ...mapState(['page', 'helper']),
  },
  created() {
    this.setInitLoadState();
    /** マスターデータ取得 */
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'helper/putMaster') this.setInitLoadState();
    });
  },
  methods: {
    /** ログアウト */
    logout(e) {
      e.preventDefault();
      this.$store.dispatch('user/login/logout');
    },
    /** postType毎の記事が読み込まれたかフラグの仕込み */
    setInitLoadState() {
      if (!Object.keys(this.helper.master).length) return;
      this.helper.master.postTypes.forEach((postType) => {
        this.postLoaded[postType.name] = false;
      });
    },

    // postコンポーネントから受け取り
    loadState(e) {
      this.postLoaded[e.name] = true;
      if (!Object.values(this.postLoaded).includes(false)) this.flag.showAbout = true;
      else this.flag.showAbout = false;
    },
  },
};
</script>

<style lang="scss" module>
.home {
}
</style>
