<template>
  <div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Spacer from '@/components/Spacer.vue';
// import Loading from '@/components/Loading.vue';

export default {
  name: 'Admin-index',
  components: {
    // Spacer,
    // Loading,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['page', 'helper']),
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
</style>
