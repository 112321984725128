<template>
  <div :class="$style.wrap">
    <ul :class="$style.sns" v-if="showSns">
      <li
        v-for="item in items"
        :key="item.type">
        <div
          v-for="l in links"
          :key="l.type">
          <a
            :href="l.link"
            target="_blank"
            v-if="item.type === l.type">
            <i :class="[item.icon, $style[item.name]]"/>
          </a>
        </div>
      </li>
    </ul>
    <a
      :href="link({ type: 4 })"
      target="blank"
      v-if="showWeb && link({ type: 4 })"
      :class="$style.weblink">WEB SITE</a>
  </div>
</template>

<script>
export default {
  name: 'sns-list',
  props: {
    links: {
      type: Array,
    },
    showSns: {
      type: Boolean,
      default: true,
    },
    showWeb: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [
        {
          type: 1,
          name: 'facebook',
          icon: 'fab fa-facebook',
        },
        {
          type: 2,
          name: 'twitter',
          icon: 'fab fa-twitter',
        },
        {
          type: 3,
          name: 'instagram',
          icon: 'fab fa-instagram',
        },
      ],
    };
  },
  methods: {
    link(item) {
      const target = this.links.find((l) => l.type === item.type);
      return target ? target.link : null;
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  align-items: center;
}
.sns {
  display: flex;
  li {
    transition: all .3s;
    &:hover {
      opacity: .6;
    }
    span {
      display: block;
      line-height: 1;

      // インスタのグラデーション
      &.ig {
          position: relative;/*相対配置*/
          display: flex;
          justify-content: center;
          align-items: center;
          background: -webkit-linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;
          background: linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;/*グラデーション①*/
          overflow: hidden;/*はみ出た部分を隠す*/
          border-radius: 6px;/*角丸に*/
          width: 25px;
          height: 25px;

          &::before {
            content: '';
            position: absolute;/*絶対配置*/
            top: 10px;/*ずらす*/
            left: -8px;/*ずらす*/
            width: 30px;/*グラデーションカバーの幅*/
            height: 30px;/*グラデーションカバーの高さ*/
            background: -webkit-radial-gradient(#ffdb2c 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0) 70%);
            background: radial-gradient(#ffdb2c 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0) 70%);/*グラデーション②*/
          }

          i {
            padding-left: 1.5px;
            font-size: 18px;
            color: #fff;
            z-index: 2;
          }
      }
    }

    i {
      font-size: 24px;

      &.facebook {
        color: #3B5998;
      }
      &.twitter {
        color: #1da1f2;
      }
      &.instagram {
        color: #CF2E92;
      }
    }
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
.weblink {
  margin-left: 18px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 20px;
  border: 1px solid rgba(#000, 0.2);
  transition: all .3s;
  &:hover {
    color: #fff;
    background-color: var(--font-black);
  }
}

@include sm-view {
  .weblink {
    margin-left: 0;
    padding: 4px 10px;
  }
}
</style>
