<template>
  <div :class="$style.wrap">
    <div v-if="detail && detail.content" :class="$style.bg" />
    <div :class="$style.content" v-if="detail">
      <Title
        :title="detail.title || detail.shopname"
        :summary="detail.summary"
        :postType="postType" />

      <Spacer :y="5" v-if="detail.author || detail.owner" />
      <Author
        v-if="(detail.author || detail.owner) && postType === 'article'"
        :author="detail.author || detail.owner"
        :type="'sm'"
        :showLinks="false"
        :remark="(postType === 'news' || postType === 'article')
          ? formatTimestamp(detail.published_at, 'YYYY/MM/DD')
          : null" />

      <Spacer :y="5"/>
      <Thumbnail
        v-if="detail.urls"
        :url="detail.urls[0]"
        :noimage="detail.noimage" />

      <Spacer :y="5" v-if="postType === 'shop'"/>
      <Info
        :shop="detail"
        v-if="postType === 'shop'"/>

      <Spacer :y="5" v-if="postType === 'work'"/>

      <Spacer :y="postType === 'shop' ? 5 : 3"/>
      <Content :content="detail.content"/>

      <Spacer
        :y="10" :smY="7"
        v-if="detail.author || detail.owner"/>
      <Author
        v-if="detail.author || detail.owner"
        :class="$style.author"
        :author="detail.author || detail.owner" />

      <Spacer :y="8" :smY="7"/>
      <p :class="$style.popular">人気の投稿</p>
      <Spacer :y="4"/>
      <ul>
        <SimpleListItem
          v-for="item in posts"
          :key="item.slug"
          :content="item"/>
      </ul>

      <Spacer :y="8"/>
    </div>
    <Loading v-else :isCenter="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer.vue';
import Loading from '@/components/Loading.vue';
import SimpleListItem from '@/components/layouts/SimpleListItem.vue';
import cf from '@/mixins/commonFunctions';
import Title from '@/components/Detail/Title.vue';
import Thumbnail from '@/components/Detail/Thumbnail.vue';
import Info from '@/components/Detail/Info.vue';
import Content from '@/components/Detail/Content.vue';
import Author from '@/components/Detail/Author.vue';

export default {
  name: 'single-post',
  mixins: [cf],
  components: {
    Spacer,
    Loading,
    SimpleListItem,
    Title,
    Thumbnail,
    Info,
    Content,
    Author,
  },
  data() {
    return {
      posts: [],
      detail: null,
      postType: null,
    };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  created() {
    this.postType = this.$route.path.split('/')[1];
    if (this.helper.master && this.helper.master.posts) {
      setTimeout(() => { this.adjustPosts(); }, 200);
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') this.adjustPosts();
      });
    }
  },
  watch: {
    $route() {
      this.detail = null;
      scrollTo(0, 0);
      setTimeout(() => { this.adjustPosts(); }, 400);
    },
  },
  methods: {
    adjustPosts() {
      this.posts = [];
      this.helper.master.posts[this.postType].forEach((row, i) => {
        row.noimage = false;
        // ページ遷移時のリセット
        if (row.urls && row.urls[0].includes('basic.png')) delete row.urls;
        // 画像なしへの対応
        if (!row.urls || !row.urls[0]) {
          row.urls = ['/img/logo/basic.png'];
          row.noimage = true;
        }
        this.posts.push(row);
        this.posts[i].num = i + 1;
        if (row.slug === this.$route.params.slug) this.detail = row;
      });
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" module>
.wrap {
  overflow: hidden;
}
.content {
  max-width: 640px;
  width: 100%;
  margin: 85px auto 0;
  color: var(--font-title);
  @include sm-view {
    margin-top: 72px;
    padding: 0 calc(100vw * (32 / 390));
  }
}
.author {
  padding: 32px 0 64px;
  border-top: 1px solid rgba(#050505, .2);
  border-bottom: 1px solid rgba(#050505, .2);
  @include sm-view {
    padding-bottom: 32px;
  }
}
.bg {
  position: absolute;
  height: 800px;
  width: 100vw;
  top: 850px;
  left: 0;
  background-color: var(--blue);
  z-index: -10;
  &::before, &::after {
    position: absolute;
    width: 100vw;
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::before {
    background-image: url('/img/dammy/detail_start.svg');
    $height: calc(100vw * (148 / 1440));
    height: $height;
    top: calc($height * -1 * 2 / 3);
  }
  &::after {
    background-image: url('/img/dammy/detail_end.svg');
    $height: calc(100vw * (167 / 1440));
    height: $height;
    bottom: calc($height * -1 * 2 / 3);
  }
}
.popular {
  font-weight: 700;
  font-size: 18px;
}
</style>
