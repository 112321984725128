<template>
  <div>
    <div class="row align-items-center mb-4 md-admin-title">
      <div class="col-lg-7 col-12"><p class="fs-3 fw-bold"><i class="fa-solid fa-party-horn me-2" />イベント一覧</p></div>
      <div class="col-lg-5 col-12">
        <div :class="$style.sticky">
          <div class="d-grid">
            <router-link
              to="/admin/event/create/"
              class="btn btn-primary"
              :class="$style.btn">
              <p>新規イベント登録</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>


    <div id="scroll-top">
      <Loading
        v-if="flag.isLoading"
        size="sm" />
      <div v-if="!flag.isLoading">
        <p v-if="!events.length && !flag.isLoading">イベントがありませんでした。<br>イベントを登録するか、検索条件を変更してください。</p>
        <ul :class="$style.event_list">
          <li v-for="row in events" :key="row.id">
            <component
              :is="toDetail(row.flag) ? 'router-link' : 'div'"
              :to="toDetail(row.flag) ? `/admin/event/update/${row.slug}` : ''">
              <div :class="$style.event_content">
                <div :class="$style.event_thumbnail_wrap">
                  <div
                    v-if="row.thumbnail && row.thumbnail.length && row.thumbnail[0].url"
                    :class="$style.event_thumbnail"
                    :style="`background-image: url(${row.thumbnail[0].url})`"/>
                  <div
                    :class="$style.event_thumbnail"
                    :style="`background-image: url('/img/noimage.jpg')`"
                    v-else/>
                  <div
                    :class="$style.event_thumbnail_status"
                    :style="`background-color: rgba(${statusColors[row.flag]}, 0.7)`">{{ helper.master.labels.articles.status[row.flag] || '読込中' }}</div>
                </div>
                <div :class="$style.event_texts">
                  <div :class="$style.event_texts_top">
                    <p :class="$style.event_texts_date">{{ formatTimestamp(row.event_date, 'YYYY.MM.DD') }}開催</p>
                    <p
                      v-if="row.categories && row.categories.length"
                      :class="$style.event_texts_category">{{ row.categories[0].label || 'カテゴリー無し' }}</p>
                  </div>
                  <p :class="$style.event_texts_title">{{ row.title }}</p>
                </div>
              </div>
            </component>
          </li>
        </ul>
        <pagination
          class="mt-4"
          :path="'/admin/event/'"
          :page="page"
          :pageMax="pageMax"
          v-if="pageMax !== 1" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import Loading from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'Admin-event-list',
  mixins: [cf],
  components: {
    Loading,
    Pagination,
  },
  data() {
    return {
      events: {},
      page: 1,
      limit: 3,
      pageMax: 1,
      flag: {
        isLoading: true,
      },
      statusColors: { // サムネイル左上のステータスの背景色を定義
        1: '135,206,235',
        2: '144,238,144',
        3: '205,92,92',
        10: '255,255,255',
      },
    };
  },
  created() {
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/admin/event/?page=1');
    }
    if (this.user.email) {
      this.getEvents();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getEvents();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  watch: {
    $route(to, from) {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
        this.getEvents();
      } else if (to.name === from.name) {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/admin/event/?page=1');
      }
    },
  },
  methods: {
    getEvents() {
      this.flag.isLoading = true;
      const params = {
        flags: [1, 2, 3, 10],
        userId: this.user.id,
        page: this.page,
        limit: this.limit,
      };
      this.axios({
        method: 'GET',
        url: '/v1/event/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.events = res.list.data;
          this.pageMax = res.list.meta.last_page;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading = false;
        });
    },
    toDetail(flag) {
      return !(flag <= 2 && this.user.role && this.user.role.role < 2);
    },
  },
};
</script>

<style lang="scss" module>
.event {
  &_list {
    padding: 0;

    li {
      border-bottom: 1px solid var(--light-gray);
    }
  }

  &_content {
    padding: 10px 0;
    display: flex;
  }

  &_thumbnail {
    height: 75px;
    width: 100px;
    margin-right: 10px;
    background-color: gray;
    background-position: center;
    background-size: cover;
    &_wrap {
      position: relative;
    }
    &_status {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      padding: 0 4px;
    }
  }

  &_texts {
    flex: 1;
    p {
      margin-bottom: 0;
    }
    &_top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }
    &_date {
      font-size: 14px;
    }
    &_category {
      display: inline-block;
      font-size: 11px;
      background-color: var(--light-gray);
      margin-left: 8px;
      padding: 2px 8px;
    }
  }
}
.sticky {
  position: sticky;
  position: -webkit-sticky; // safari対応
  top: 140px;
}

.btn {
  p {
    text-decoration: none;
    color: white;
    &:link, &:visited, &:hover, &:active {
      color: white;
    }
  }
}

</style>
