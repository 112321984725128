<template>
  <div>
    <h1 :class="$style.title">{{ title }}</h1>
    <spacer
      :y="2"
      v-if="summary && postType !== 'article'" />
    <p
      v-if="summary && postType !== 'article'"
      class="n2br"
      v-html="summary"></p>
  </div>
</template>

<script>
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'title',
  components: {
    Spacer,
  },
  props: {
    postType: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
    },
  },
};
</script>

<style lang="scss" module>
.title {
  font-weight: bold;
  font-size: 28px;
  line-height: 1.5;
}

@include sm-view {
  .title {
    font-size: 24px;
  }
}
</style>
