import moment from 'moment';
import axios from '@/plugins/axios';

export default {
  async registEvent(params) {
    let result = params;
    result = await this.registBaseData(result);
    if (result.resultType !== 'error') result = await this.registThumbnail(result);
    // if (result.resultType !== 'error' && result.categoryData.category_id !== null) result = await this.registCategoryRelation(result);
    // if (result.resultType !== 'error' && result.tagData.tagIds.length) result = await this.registTagRelation(result);
    // if (result.resultType !== 'error') result = await this.registAuthor(result);
    return result;
  },

  async registBaseData(params) {
    let result = params;
    const data = params.eventData;
    if (!data.published_at) data.published_at = moment(new Date()).format('YYYY-MM-DD HH:mm:00');
    else data.published_at = moment(params.eventData.published_at).format('YYYY-MM-DD HH:mm:00');
    if (!data.event_date) data.event_date = moment(new Date()).format('YYYY-MM-DD HH:mm:00');
    else data.event_date = moment(params.eventData.event_date).format('YYYY-MM-DD HH:mm:00');
    await axios({
      method: 'POST',
      url: `/v1/event/${params.type}`,
      data,
    })
      .then((response) => {
        result.eventData.id = response.data.eventId;
        result.resultType = 'success';
        if (response.data && response.data.exists) {
          result.resultType = 'error';
          return alert('イベントのslugが重複しています。\n異なるものを設定してください。');
        }
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        if (error.message) console.log(error.message);
        else console.log(error);
      });

    return result;
  },

  async registThumbnail(params) {
    let result = params;
    const data = {
      foreign_type: 13,
      foreign_id: params.eventData.id,
      media_id: params.thumbnail.media_id,
      is_primary: 1,
    };
    axios({
      method: 'POST',
      url: '/v1/mediaRelation/create',
      data,
    })
      .then(() => {
        result.resultType = 'success';
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        if (error.message) console.log(error.message);
        else console.log(error);
      });
    return result;
  },

  async registGallery(params) {
    let result = params;
    result = await this.registGalleryData(result);
    if (result.resultType !== 'error' && params.event_id && result.type !== 'updater') await this.registGalleryRelation(result);
    return result;
  },

  async registGalleryData(params) {
    let result = params;
    const data = params.galleryData;
    let endpoint = 'create';
    if (params.galleryData.id) {
      data.id = params.galleryData.id;
      endpoint = 'update';
      params.type = 'update';
    }

    await axios({
      method: 'POST',
      url: `/v1/gallery/${endpoint}`,
      data,
    })
      .then((response) => {
        result.resultType = 'success';
        result.gallery_id = response.data.galleryId;
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        alert('ギャラリーの登録に失敗しました。');
        if (error.message) console.log(error.message);
        else console.log(error);
      });
    return result;
  },

  async registGalleryRelation(params) {
    let result = params;
    const data = {
      post_type: 3,
      post_id: params.event_id,
      gallery_id: params.gallery_id,
    };
    await axios({
      method: 'POST',
      url: '/v1/postGallery/set/relation',
      data,
    })
      .then(() => {
        result.resultType = 'success';
      })
      .catch((error) => {
        result = {
          resultType: 'error',
        };
        alert('ギャラリーの登録に失敗しました。');
        if (error.message) console.log(error.message);
        else console.log(error);
      });
    return result;
  },

  // // カテゴリー・タグ登録
  // async registItem(params) {
  //   const data = params.itemData;
  //   const result = {};
  //   await axios({
  //     method: 'POST',
  //     url: `/v1/${params.name}/set/${params.type}`,
  //     data,
  //   })
  //     .then((response) => {
  //       const res = response.data;
  //       result.item = res[`${params.name}`];
  //       result.resultType = 'success';
  //       result.exists = res.exists;
  //     })
  //     .catch((error) => {
  //       if (error.message) console.log(error.message);
  //       else console.log(error);
  //       result.resultType = 'error';
  //     });

  //   return result;
  // },
};
