<template>
  <ColumnContainer>
    <MainColumn>
      <div :class="$style.container">
        <div :class="$style.noResult">
          <ErrorMessage>{{ message }}</ErrorMessage>
        </div>
      </div>
    </MainColumn>
    <SideColumn />
  </ColumnContainer>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'NotFound',
  components: { ErrorMessage },
  props: {
    message: {
      type: String,
      required: false,
      default: 'お探しのページは見つかりませんでした',
    },
  },
};
</script>

<style lang="scss" module>
.container {
  max-width: 720px;
  width: 100%;
  margin: auto;
}

.total {
  font-size: 16px;
}

.noResult {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
