<template>
  <div :class="[$style.wrapper, $style[page.name]]">
    <AdminGlobalHeader v-if="isAdminPage" />
    <GlobalHeader v-if="!isAdminPage && !isLoginPage" />
    <div :class="$style.admin_wrapper" class="row" v-if="isAdminPage">
      <admin-side-menu class="col-md-2 col-12" />
      <router-view :class="$style.admin" class="col-md-10 col-12" />
    </div>
    <main v-else :class="[$style.main, $style[page.name]]">
      <router-view />
    </main>
    <GlobalFooter />
    <ContentsModal />
    <LoadingsModal />
    <MessagesModal />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { throttle } from 'lodash';
import {
  AdminGlobalHeader,
  AdminSideMenu,
  GlobalHeader,
  GlobalFooter,
} from '@/components/layouts';
import ContentsModal from '@/components/modal/Contents.vue';
import LoadingsModal from '@/components/modal/Loadings.vue';
import MessagesModal from '@/components/modal/Messages.vue';

export default {
  name: 'App',
  components: {
    AdminGlobalHeader,
    AdminSideMenu,
    GlobalHeader,
    GlobalFooter,
    ContentsModal,
    LoadingsModal,
    MessagesModal,
  },
  computed: {
    ...mapState(['page', 'scroll']),
  },
  data() {
    return {
      throttle: {
        scroll: null,
        resize: null,
      },
      isAdminPage: false,
      isLoginPage: false,
    };
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
  },
  mounted() {
    this.setMetas(this.$route, this.$store);
    this.throttle.resize = throttle(this.checkTabWidth, 200);
    addEventListener('resize', this.throttle.resize, true);
    this.checkTabWidth();
  },
  unmounted() {
    removeEventListener('resize', this.throttle.resize, true);
  },
  watch: {
    $route(to) {
      this.setMetas(to, this.$store);

      // トップのスクロール処理
      if (this.page && this.page.name === 'Home') {
        this.throttle.scroll = throttle(this.handleScroll, 200);
        window.addEventListener('scroll', this.throttle.scroll, true);
      } else {
        const args = { type: 'scroll', data: { top: null, bottom: null } };
        this.$store.dispatch('scroll/setData', args, { root: true });
        window.removeEventListener('scroll', this.throttle.scroll, true);
      }
      // adminページ処理
      if (to.name.includes('Admin')) this.isAdminPage = true;
      else this.isAdminPage = false;
      if (to.name === 'Login') this.isLoginPage = true;
      else this.isLoginPage = false;
    },
  },
  methods: {
    setMetas: (route, store) => {
      const meta = {};
      // 個別設定がない場合のデフォルト設定
      const defaults = {
        title: 'ファン桐生',
        description: '',
        og_image: 'https://kiryu.city/img/ogp.png',
      };

      meta.name = route.name;
      meta.title = route.meta.title ? `${route.meta.title} | ${defaults.title}` : defaults.title;
      meta.description = route.meta.description || defaults.description;

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // store.pageに保持させる
      store.dispatch('page/setPageInfo', meta);
    },

    checkTabWidth() {
      const W = window.innerWidth;
      this.$store.dispatch('view/setDisplaySize', W, { root: true });
    },


    /** スクロール処理（トップでのみ発火） */
    handleScroll(e) {
      if (!this.scroll.positions.home.firstView) return;
      // スクロール値をstore登録・処理もstoreで行う
      const args = {
        type: 'scroll',
        data: {
          top: e.target.scrollTop,
          bottom: e.target.scrollTop + window.innerHeight,
        },
      };
      this.$store.dispatch('scroll/setData', args, { root: true });
      // storeでスクロールの処理
      this.$store.dispatch('scroll/handleScroll', null, { root: true });
    },
  },
};
</script>

<style lang="scss">
// @use "sass:math";
@import '../node_modules/loaders.css/loaders.min.css';
@import '@/assets/scss/_keyframes.scss';
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/modal.scss';
@import '@/assets/scss/style.scss';
</style>


<style lang="scss" module>
.wrapper {
  position: relative;
  min-height: 100vh;
  padding-bottom: 165px; // footer分
  &.Login {
    padding-bottom: 100px;
  }
}

.admin {
  padding: 25px 50px;
  &_wrapper {
    position: relative;
    padding-top: 70px;
  }
  @include md-view {
    padding: 3rem 2rem;
  }
}

.main {
  &:not(.Home) {
    padding-top: calc(var(--s) * 10);
  }
  &.Login {
    padding-top: 0;
    height: calc(100vh - 100px);
  }
}
</style>
