<template>
  <header
    :class="[
      $style.header,
      getInitHide(),
      scroll.flag.home.header ? $style.isShow : '',
      view.display_size !== 'sm' ? '' : $style.sp]">
    <div class="container">
      <div class="d-flex" :class="$style.inner">
        <component
          v-bind:is="headTag"
          class="image"
          :class="$style.logo"
          @click="scholltop">
          <router-link to='/' class="image-body">Fun Kiryu</router-link>
        </component>

        <GlobalNav />
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import VueScrollTo from 'vue-scrollto';
import GlobalNav from './GlobalNav';

export default {
  name: 'GlobalHeader',
  components: {
    GlobalNav,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['page', 'scroll', 'view']),
    headTag() {
      return this.$route.name === 'Home' ? 'h1' : 'p';
    },
  },
  created() {
  },
  methods: {
    /** 初期非表示（トップ） */
    getInitHide() {
      return this.page.name === 'Home'
        ? this.$style.initHide
        : '';
    },
    scholltop() {
      const element = document.getElementById('topAnker');
      VueScrollTo.scrollTo(element, 500, { offset: 0 });
    },
  },
};
</script>

<style lang="scss" module>
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: white;
  &.initHide {
    background-color: transparent;
    transition: all .5s;
    .logo {
      opacity: 0;
      pointer-events: none;
      transition: all .5s;
    }
    &.isShow {
      background-color: white;
      .logo {
        opacity: 1;
        pointer-events: auto;
      }
      &.sp {
        background-color: transparent;
      }
    }
  }
  &.sp {
    background-color: transparent;
  }
}
.inner {
  padding: 12px 0;
}
.logo {
  position: relative;
  max-width: 193.55px;
  flex: 1;
  a {
    position: absolute;
    top: 8px;
    left: 0;
    padding-top: percentage(134/193.55);
    background-image: url(/img/logo/basic.svg);
    transition: all .4s;
    &:hover {
      opacity: .7;
    }
  }
}

@include sm-view {
  .logo {
    max-width: 130px;
    a {
      top: 0;
      padding-top: percentage(460/662);
      background-image: url(/img/logo/basic.png);
    }
  }
}
</style>
