<template>
  <div>
    <ul :class="$style.nums">
      <li
        :class="$style.arrow"
        v-if="page !== 1">
        <router-link
          v-if="pageMax > 5"
          :to="{
            path,
            query: getQuery(1)
          }">
          <i class="fa-regular fa-chevrons-left"></i>
        </router-link>
        <spacer v-if="pageMax > 5" :x="3"/>
        <router-link
          :to="{
            path,
            query: getQuery(page - 1)
          }">
          <i class="fa-regular fa-chevron-left"></i>
        </router-link>
        <spacer :x="1"/>
      </li>
      <li
        v-for="n of displayPages"
        :key="n">
        <router-link
          :class="className(n)"
          :to="{
            path,
            query: getQuery(n)
          }">{{ n }}</router-link>
      </li>
      <li
        v-if="page !== pageMax && pageMax !== 0"
        :class="$style.arrow">
        <spacer :x="1"/>
        <router-link
          :to="{
            path,
            query: getQuery(page + 1)
          }">
          <i class="fa-regular fa-chevron-right"></i>
        </router-link>
        <spacer v-if="pageMax > 5" :x="3"/>
        <router-link
          v-if="pageMax > 5"
          :to="{
            path,
            query: getQuery(pageMax)
          }">
          <i class="fa-regular fa-chevrons-right"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'pagination',
  props: {
    path: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      default: 0,
    },
    pageMax: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Spacer,
  },
  data() {
    return {
      displayPages: [],
    };
  },
  created() {
    this.generatePage(this.page);
  },
  watch: {
    page(to) {
      this.generatePage(to);
    },
    pageMax() {
      // ページ生成時にpageMaxの初期値が来てしまうので後の値を監視しておく
      const nowPage = this.page;
      this.generatePage(nowPage);
    },
  },
  methods: {
    generatePage(nowPage) {
      if (this.pageMax > 5) {
        if (nowPage < 4) { // 現在のページ番号 1 ~ 3
          this.displayPages = [1, 2, 3, 4, 5];
        } else if (nowPage > this.pageMax - 2) { // 現在のページ番号が最後から２ページ以上
          this.displayPages = [...Array(5).keys()].map((e) => e + this.pageMax - 4);
        } else {
          const now = nowPage;
          this.displayPages = [...Array(5).keys()].map((e) => e + now - 2);
        }
      } else {
        this.displayPages = this.pageMax;
      }
    },
    className(n) {
      let name = this.$style.num;
      if (n === this.page) name += ` ${this.$style.active}`;
      return name;
    },
    getQuery(n) {
      const query = {};
      Object.keys(this.$route.query).forEach((key) => {
        if (key !== 'page') query[key] = this.$route.query[key];
        else query.page = n;
      });
      return query;
    },
  },
};
</script>

<style lang="scss" module>
.nums {
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
}
.num {
  padding: 5px 12px;
  border: 1px solid var(--font-black);
  cursor: pointer;
  &.active {
    background-color: var(--font-black);
    color: white;
  }
}
.arrow {
  cursor: pointer;
  display: flex;
}
</style>
