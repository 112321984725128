<template>
  <div :class="$style.posts">
    <div class="container">
      <h2 class="title section">{{ postType.name.toUpperCase() }}<small v-if="view.display_size !== 'sm'">{{ postType.label }}</small></h2>
      <p v-if="view.display_size === 'sm'" :class="$style.spLabel">{{ postType.label }}</p>

      <spacer :y="6" />
      <div :class="$style['posts-wrapper']">
        <div :class="$style['horizontal-cards']" v-if="posts.length">
          <card
            v-for="row in adjustedPosts(posts)"
            :key="row.slug"
            :isSquare="true"
            :isHome="true"
            :postType="postType"
            :data="row" />
        </div>

        <div v-else>
          <p>{{ getPostTypeLabel(postType.name) }}の記事はただいま準備中です。</p>
        </div>
      </div>

      <spacer :y="4" v-if="posts.length > 3" />
      <div
        :class="$style.more"
        v-if="posts.length > 3">
        <router-link :to="`/${postType.name}/`">MORE<i class="fa-light fa-arrow-right"></i></router-link>
      </div>
    </div>
    <spacer :y="15.5" v-if="postType.name !== 'shop'" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import xml2js from 'xml2js';
import Spacer from '@/components/Spacer.vue';
import Card from '@/components/Post/Card.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Home-posts',
  mixins: [cf],
  components: {
    Spacer,
    Card,
  },
  props: {
    postType: {
      type: Object,
    },
  },
  data() {
    return {
      posts: [],
      windowSize: 'lg',
    };
  },
  created() {
    this.posts = [];
    this.getPosts();
  },
  async mounted() {
    /** ダミーデータ取得 */
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'helper/putMaster') this.getPosts();
    });
  },
  computed: {
    ...mapState(['helper', 'view']),
  },
  methods: {
    /**
     * トップ投稿取得
     */
    async getPosts() {
      if (this.postType.name === 'news') {
        this.getTimes();
      } else if (this.postType.name === 'educate') {
        this.getEducation();
      }
      // 取り急ぎダミー
      // 実際にはpostType.nameのデータを取得する
      if (!Object.keys(this.helper.master).length) return;
      const posts = cloneDeep(this.helper.master.posts[this.postType.name]);
      if (!posts) {
        this.$emit('loaded', { name: this.postType.name });
        return;
      }
      // 画像の向きを取得する
      const inPiecesPosts = [];
      await Promise.all(
        posts.map(async (post) => {
          // 暫定処理
          if (!post.urls || !post.urls[0]) {
            post.urls = ['/img/logo/basic.png'];
            post.noimage = true;
          }
          const imageDirection = await cf.getImageDirection(post.urls[0]);
          post.direction = imageDirection;
          inPiecesPosts.push(post);
        }),
      );

      // direction取得時に順番狂うためソート
      const sortedPosts = await cf.ObjectValueSort(inPiecesPosts);
      sortedPosts.forEach((p) => { this.posts.push(p); });
      // 描画の完了を待って親へ通知
      await this.$nextTick();
      this.$emit('loaded', { name: this.postType.name });
    },

    adjustedPosts(posts) {
      if (this.view.display_size !== 'lg' && posts.length > 4) return posts.slice(0, 4);
      return posts;
    },

    /**
     * 投稿タイプ名からラベル取得
     */
    getPostTypeLabel(postType) {
      if (!postType || !this.helper || !this.helper.master) return;
      const posttype = this.helper.master.postTypes.filter((pt) => {
        const name = pt.name;
        return name === postType;
      });
      return posttype[0].label;
    },

    /**
     * タイムスの記事を取得する
     */
    async getTimes() {
      await this.axios({
        method: 'GET',
        url: '/v1/news/get/times',
      })
        .then((response) => {
          // rss取得に成功したらxmlをパース
          xml2js.parseString(response.data.news.data, (error, result) => {
            if (error) {
              console.log(error);
            } else {
              // 各項目に取得したものを埋め込み
              result.rss.channel[0].item.forEach((elem) => {
                this.posts.push({
                  title: elem.title[0],
                  urls: elem.enclosure[0].$.url,
                  summary: elem.description,
                  link: elem.link[0],
                });
              });
            }
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /**
     * 教育に表示するコンテンツを取得
     */
    async getEducation() {
      await this.axios({
        method: 'GET',
        url: '/v1/category/get/education',
      })
        .then((response) => {
          // rss取得に成功したらxmlをパース
          xml2js.parseString(response.data.contents, (error, result) => {
            if (error) {
              console.log(error);
            } else {
              result.rss.channel[0].item.forEach((elem) => {
                let noimage = false;
                const searchTarget = elem['content:encoded'][0];
                const start = searchTarget.indexOf('src="');
                let imgUrl;
                if (start <= 0) { // 画像のない記事の場合
                  imgUrl = '/img/logo/basic.png';
                  noimage = true;
                } else { // 記事内に画像がある
                  const end = searchTarget.indexOf('" ', start + 5); // 'src='の文字列分の長さ
                  imgUrl = searchTarget.slice((start + 5), end);
                }

                this.posts.push({
                  title: elem.title[0],
                  urls: imgUrl,
                  summary: elem.description,
                  link: elem.link[0],
                  noimage,
                });
              });
            }
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /**
     * postTypeによって遷移先をサイト内なのか外部サイトかを判定
     * サイト内リンクであればfalseを返し、外部サイトならURLを返す
     * pageコンテンツ追加に伴い修正する際に漏れをなくすためcfに記載
     */
    getLink(type) {
      const result = cf.getLink(type);
      return result;
    },
  },
};
</script>

<style lang="scss" module>
$pd: 12px;
.posts {
  overflow: hidden;
}
.spLabel {
  font-weight: 700;
  font-size: 12px;
  margin-top: 12px;
  text-align: center;
}
.horizontal-cards {
  display: flex;
  margin-left: -$pd;
  > * {
    &:not(:first-child) {
      margin-left: 32px - ($pd * 2);
    }
  }
}
.more {
  display: flex;
  justify-content: flex-end;
  a {
    font-size: 16px;
    font-weight: 700;
    transition: all .4s;
    &:hover {
      opacity: .7;
    }
  }
  i {
    margin-left: 1em;
  }
}
@include sm-view {
  .horizontal-cards {
    margin: 0 -1.5vw;
    width: calc(100% + 12px);
    flex-wrap: wrap;
    > * {
      &:not(:first-child) {
        margin-left: 0;
      }
      &:not(:nth-child(-n+2)) {
        margin-top: 40px;
      }
    }
  }
}
</style>
